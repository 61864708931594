import { all } from 'redux-saga/effects';

import { saga as mainPageSaga } from '@features/DynamicPage/components/MainPage/duck/saga';
import { saga as eventSelectorSaga } from '@features/EventSelector/duck/saga';
import { saga as mainButtonSaga } from '@features/MainButton/duck/saga';

export function* sagas() {
  yield all([
    mainPageSaga(),
    eventSelectorSaga(),
    mainButtonSaga(),
  ]);
}
