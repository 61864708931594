import React from 'react';
import classNames from 'classnames';

import { ButtonModern } from '@common/components/ButtonModern/ButtonModern';
import { ITextSlide } from '@features/TextSlide/interfaces/ITextSlide';

export const TextSlide: React.FC<ITextSlide> = ({ title, button, isFullHeight }) => (
  <div
    className={classNames('slider-item slider-item--text', {
      'slider-item--height-100': isFullHeight,
    })}
  >
    <div className='slider-item__title'>{title}</div>
    {button && (
      <div className='slider-item__button'>
        <ButtonModern
          href={button.action.href}
          isBigHeight={true}
        >
          {button.text}
        </ButtonModern>
      </div>
    )}
  </div>
);
