import { connect, InferableComponentEnhancer } from 'react-redux';
import { Dispatch } from 'redux';

import { IRootState } from '@apps/main/initStore';
import {
  fetchStatusSelector,
  mainPageAnalyticsSelector,
  mainPageSelector,
} from '@features/DynamicPage/components/MainPage/duck/selectors';
import { asyncActions } from '@features/DynamicPage/components/MainPage/duck/slice';

const mapStateToProps = (state: IRootState) => ({
  blocks: mainPageSelector(state),
  analytics: mainPageAnalyticsSelector(state),
  fetchStatus: fetchStatusSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onInit: () => dispatch(asyncActions.onInitMainPage()),
});

export type IStateProps = ReturnType<typeof mapStateToProps>;

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

export type IWithMainPageState = IStateProps & IDispatchProps;

export const withMainPageState: InferableComponentEnhancer<IWithMainPageState> = component => (
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(component)
);
