import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@apps/main/initStore';
import { getEventsFromResponse } from '@features/EventSelector/utils/getEventsFromResponse';

export const availableCalendarsSelector = (state: IRootState) => state.eventSelector.availableCalendars;
export const fetchStatusSelector = (state: IRootState) => state.eventSelector.fetchStatus;

export const eventsSelector = createSelector(
  availableCalendarsSelector,
  getEventsFromResponse,
);

