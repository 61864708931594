import React from 'react';
import { useComponentRegistry } from '@bem-react/di';

import { ICardDouble } from '@features/CardConnector/interfaces/ICardDouble';
import { IComponentRegistry } from '@features/CardConnectorDouble/interfaces/IComponentRegistry';

export const cardConnectorDoubleRegistryToken = 'cardConnectorDoubleRegistryToken';

export const CardConnectorDouble: React.FC<ICardDouble> = ({ cards }) => {
  const { CardConnector } = useComponentRegistry<IComponentRegistry>(cardConnectorDoubleRegistryToken);

  return (
    <div className='card-double'>
      <div className='card-double__item'>
        <CardConnector
          isShort={true}
          {...cards[0]}
        />
      </div>
      <div className='card-double__item'>
        <CardConnector
          isShort={true}
          isRightBorderRadius={true}
          {...cards[1]}
        />
      </div>
    </div>
  );
};
