import { useEffect, useRef } from 'react';

export const useOnButtonHover = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const onMouseOver = (e: MouseEvent) => {
      const target = e.target as HTMLDivElement | null;

      if (target && (target.classList.contains('js-button') || target.closest('.js-button'))) {
        ref.current?.classList.add('_hovered');
      }
    };

    const onMouseOut = (e: MouseEvent) => {
      const target = e.target as HTMLDivElement | null;

      if (target && target.classList.contains('js-button')) {
        ref.current?.classList.remove('_hovered');
      }
    };

    ref.current?.addEventListener('mouseover', onMouseOver);
    ref.current?.addEventListener('mouseout', onMouseOut);

    return () => {
      ref.current?.removeEventListener('mouseover', onMouseOver);
      ref.current?.removeEventListener('mouseout', onMouseOver);
    };
  }, []);

  return ref;
};
