import { Dispatch } from 'redux';
import { connect, InferableComponentEnhancer } from 'react-redux';

import { actions } from '@features/MainButton/duck/slice';
import { popupSelector } from '@features/MainButton/duck/selectors';
import { IWithMainButtonRootState } from '@features/MainButton/interfaces/withMainButtonRootState';

const mapStateToProps = (state: IWithMainButtonRootState) => ({
  popup: popupSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetPopup: () => dispatch(actions.resetPopup()),
});

export type IStateProps = ReturnType<typeof mapStateToProps>;

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

export type IWithMainButtonPopupState = IStateProps & IDispatchProps;

export const withMainButtonPopupState: InferableComponentEnhancer<IWithMainButtonPopupState> = component => (
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(component)
);
