import { CardTypes } from '@features/AbonementTypes/enums/CardTypes';
import { IType } from '@common/interfaces/commonResponse/type';
import { ICardSaleType } from '@features/AbonementTypes/interfaces/IType';
import { getAbonementSaleCardFromResponse } from '@features/AbonementCard/utils/getAbonementSaleCardFromResponse';

export const getAbonementSaleCardTypeFromResponse = (type: IType): ICardSaleType => ({
  type: CardTypes.CARD_SALE,
  shortName: type.shortName || type.name,
  card: getAbonementSaleCardFromResponse(type),
});
