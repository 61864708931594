import React from 'react';
import classNames from 'classnames';

import { IEventInfo } from '@features/Event/interfaces/eventInfo';
import { EventLarge } from '@features/Event/Event@large';
import { EventMiddle } from '@features/Event/Event@middle';
import { EventTiny } from '@features/Event/Event@tiny';
import { EventBig } from '@features/Event/Event@big';
import { HiddenClasses } from '@features/Event/enum/HiddenClasses';

export interface IProps {
  events: IEventInfo[];
  onResetButtonClick?: () => void;
  isMobile?: boolean;
  isTablet?: boolean;
  className?: string;
}

export const Result: React.FC<IProps> = ({
  events,
  onResetButtonClick,
  isMobile,
  isTablet,
  className,
}) => {
  const EventItem: React.FC<{ item: IEventInfo }> = ({ item }) => {
    if (isTablet) {
      return (
        <EventMiddle item={item}/>
      );
    }

    if (isMobile) {
      return (
        <EventTiny item={item}/>
      );
    }

    return (
      <>
        <EventBig
          item={item}
          className={HiddenClasses.DESKTOP_BIG}
        />
        <EventLarge
          item={item}
          className={HiddenClasses.DESKTOP_SMALL}
        />
      </>
    );
  };

  return (
    <div
      className={classNames('event-selector__result', {
        'event-selector__result--empty': !events.length,
      }, className)}
    >
      {events.length ? (
        <>
          {events.map(eventItem => (
            <div
              key={eventItem.id}
              className='event-selector__result-item'
            >
              <EventItem item={eventItem}/>
            </div>
          ))}
        </>
      ) : (
        <div className='event-selector__result-caption'>
          Увы, мы ничего не нашли. Попробуйте изменить даты или тип события
        </div>
      )}

      {isMobile && (
        <div
          className='event-selector__sticky-button'
          onClick={onResetButtonClick}
        >
          изменить даты
        </div>
      )}
    </div>
  );
};
