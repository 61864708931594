import { ButtonTypes } from '@common/enums/ButtonTypes';
import { IButtonAction } from '@common/interfaces/commonResponse/button';
import { IOnCheckPersonRestrictions } from '@common/interfaces/onCheckPersonRestrictions';
import { IShowPopup } from '@features/MainButton/interfaces/showPopup';

interface IParams {
  action: IButtonAction;
  showPopup?: IShowPopup;
  onCheckPersonRestrictions?: IOnCheckPersonRestrictions;
}

export const getOnButtonClick = ({
  action,
  showPopup,
  onCheckPersonRestrictions,
}: IParams) => {
  if (action.checkPersonRestrictions && onCheckPersonRestrictions) {
    return () => onCheckPersonRestrictions(action.bitrixEventId, action.href);
  }

  if (action.type === ButtonTypes.POPUP && showPopup) {
    return () => showPopup(action.popupText);
  }
};

