import React from 'react';
import ReactDOM from 'react-dom';

import { AppDesktop } from '@apps/main/app@desktop';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <AppDesktop/>,
    document.getElementById('main'),
  );
});
