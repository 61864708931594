export enum DynamicPageBlocks {
  ABONEMENT_TEXT = 'abonementText',
  ABONEMENT_TYPES = 'abonementTypes',
  SLIDER = 'slider',
  ABONEMENT_INFO = 'abonementInfo',
  EVENT_LIST_CARD = 'eventListCard',
  CARD = 'card',
  CARD_DOUBLE = 'cardDouble',
  EVENT_SELECTOR = 'eventSelector',
  VIP_COVER = 'vipCover',
  VIP_BUSINESS_CLUBS = 'vipBusinessClubs',
  VIP_LODGES = 'vipLodges',
  VIP_ABOUT = 'vipAbout',
  VIP_CONTACT_INFO = 'vipContactInfo',
  VIP_CLOSEST_EVENTS = 'vipClosestEvents',
  VIP_BUSINESS_PROGRAM = 'vipBusinessProgram',
  VIP_FOOD_MENU = 'vipFoodMenu',
  VIP_ORDER_FORM = 'vipOrderForm',
  VIP_SERVICE_PACKAGES = 'vipServicePackages',
  VIP_OTHER_BC = 'vipOtherBC',
  VIP_ENTERTAINMENT = 'vipEntertainment',
}
