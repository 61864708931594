export enum ServiceTokens {
  STADIUM_API_SERVICE = 'STADIUM_API_SERVICE',
  PANORAMA_API_SERVICE = 'PANORAMA_API_SERVICE',
  ABONEMENT_API_SERVICE = 'ABONEMENT_API_SERVICE',
  MAIN_PAGE_API_SERVICE = 'MAIN_PAGE_API_SERVICE',
  CALENDAR_API_SERVICE = 'CALENDAR_API_SERVICE',
  TICKET_TRANSFER_API_SERVICE = 'TICKET_TRANSFER_API_SERVICE',
  VIP_API_SERVICE = 'VIP_API_SERVICE',
  SUBSCRIBE_API_SERVICE = 'SUBSCRIBE_API_SERVICE',
  RESERVE_API_SERVICE = 'RESERVE_API_SERVICE',
  PAGE_BUILDER_API_SERVICE = 'PAGE_BUILDER_API_SERVICE',
  CERTIFICATE_API_SERVICE = 'CERTIFICATE_API_SERVICE',
  TICKET_CHECKER_API_SERVICE = 'TICKET_CHECKER_API_SERVICE',
  PAYMENT_PAGE_API_SERVICE = 'PAYMENT_PAGE_API_SERVICE',
}
