import React, { ImgHTMLAttributes } from 'react';

export interface IProps extends ImgHTMLAttributes<HTMLImageElement> {
  icon: string | null;
  size?: number;
}

export const Emoji: React.FC<IProps> = ({ icon, size, ...props }) => (
  <img
    {...props}
    src={icon || undefined}
    width={size}
  />
);
