import { Dispatch } from 'redux';
import { connect, InferableComponentEnhancer } from 'react-redux';

import { actions, asyncActions } from '@features/MainButton/duck/slice';
import { fetchStatusSelector } from '@features/MainButton/duck/selectors';
import { IWithMainButtonRootState } from '@features/MainButton/interfaces/withMainButtonRootState';

const mapStateToProps = (state: IWithMainButtonRootState) => ({
  fetchStatus: fetchStatusSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  showPopup: (text: string) => dispatch(actions.showPopup(text)),
  onCheckPersonRestrictions: (calendarBitrixId: number, redirectLocation: string) => dispatch(
    asyncActions.onCheckPersonRestrictions({ calendarBitrixId, redirectLocation }),
  ),
});

export type IStateProps = ReturnType<typeof mapStateToProps>;

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

export type IWithMainButtonState = IStateProps & IDispatchProps;

export const withMainButtonState: InferableComponentEnhancer<IWithMainButtonState> = component => (
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(component)
);
