import { connect, InferableComponentEnhancer } from 'react-redux';
import { Dispatch } from 'redux';

import { IRootState } from '@apps/main/initStore';
import { IAvailableCalendarsParams } from '@common/interfaces/calendarRequest/availableCalendarsParams';
import { asyncActions, actions } from '@features/EventSelector/duck/slice';
import { eventsSelector, fetchStatusSelector } from '@features/EventSelector/duck/selectors';
import { IDateRange } from '@features/EventSelector/components/DateRangePicker/interfaces/dateRange';

const mapStateToProps = (state: IRootState) => ({
  events: eventsSelector(state),
  fetchStatus: fetchStatusSelector(state),
  chosenVariant: state.eventSelector.chosenVariant,
  dateRange: state.eventSelector.dateRange,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onGetAvailableCalendars: (eventParams?: IAvailableCalendarsParams) => (
    dispatch(asyncActions.onGetAvailableCalendars(eventParams))
  ),
  resetAvailableCalendars: () => dispatch(actions.setAvailableCalendars(null)),
  setChosenVariant: (variant: string) => {
    dispatch(actions.setChosenVariant(variant));
  },
  setDateRange: (range: IDateRange) => {
    dispatch(actions.setDateRange(range));
  },
});

export type IStateProps = ReturnType<typeof mapStateToProps>;

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

export type IWithEventSelectorState = IStateProps & IDispatchProps;

export const withEventSelectorState: InferableComponentEnhancer<IWithEventSelectorState> = component => (
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(component)
);
