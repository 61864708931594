import { useEffect, useState } from 'react';

import { IDateRange } from '@features/EventSelector/components/DateRangePicker/interfaces/dateRange';
import { isDateRangeFulfilled } from '@features/EventSelector/components/DateRangePicker/typeGuards/isDateRangeFulfilled';
import { DateTypes } from '@features/EventSelector/enum/DateTypes';

interface IParams {
  range: IDateRange;
}

export const useCurrentDate = ({ range }: IParams) => {
  const [currentDate, setCurrentDate] = useState<DateTypes | null>(null);

  useEffect(() => {
    setCurrentDate(prevDate => {
      const isDateFulfilled = isDateRangeFulfilled(range);

      if (!isDateFulfilled && prevDate === DateTypes.TO) {
        return DateTypes.FROM;
      }

      if (prevDate === DateTypes.FROM) {
        return DateTypes.TO;
      }

      return null;
    });
  }, [range]);

  return {
    currentDate,
    setCurrentDate,
  };
};
