import { Registry, withRegistry } from '@bem-react/di';

import { AbonementSlide, abonementSlideRegistryToken } from '@features/AbonementSlide/AbonementSlide';
import { AbonementCardDesktop } from '@features/AbonementCard/AbonementCard@desktop';
import { RegistryTokens } from '@features/AbonementSlide/enums/RegistryTokens';

const registry = new Registry({ id: abonementSlideRegistryToken });

registry.set(RegistryTokens.ABONEMENT_CARD, AbonementCardDesktop);

export const AbonementSlideDesktop = withRegistry(registry)(AbonementSlide);
