import { IEventList } from '@common/interfaces/pageBlocks/eventList';
import { IEventListCard } from '@features/EventListCard/interfaces/IEventListCard';
import { getEventInfoFromResponse } from '@features/Event/utils/getEventInfoFromResponse';
import { DynamicPageBlocks } from '@features/DynamicPage/enums/DynamicPageBlocks';
import { DynamicPageRoutes } from '@common/enums/DynamicPageRoutes';
import { getEventAnalyticsInfo } from '@features/DynamicPage/utils/getEventAnalyticsInfo';

export const getEventListCardFromResponse = (
  response: IEventList,
  blockType?: DynamicPageBlocks,
  pageRoute?: DynamicPageRoutes,
): IEventListCard => ({
  backgroundImage: response.backgroundImage,
  backgroundVideo: response.backgroundVideo,
  title: response.title,
  events: response.events.map(event => {
    const eventAnalyticsInfo = getEventAnalyticsInfo({ pageRoute, event });

    return getEventInfoFromResponse(event, eventAnalyticsInfo?.product);
  }),
  button: response.button,
});
