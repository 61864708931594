import moment from 'moment';

import { IAvailableCalendarsParams } from '@common/interfaces/calendarRequest/availableCalendarsParams';
import { IDateRange } from '@features/EventSelector/components/DateRangePicker/interfaces/dateRange';
import { DATE_FORMAT_RFC2822 } from '@features/EventSelector/utils/constants';
import { VARIANT_DEFAULT } from '@features/EventSelector/components/Select/utils/constants';

interface IParams {
  dateRange: IDateRange | null;
  chosenVariant?: string;
}

export const getEventsParams = ({
  dateRange,
  chosenVariant,
}: IParams): IAvailableCalendarsParams => {
  const dateFrom = dateRange?.from
    ? moment.parseZone(dateRange?.from).locale('en').format(DATE_FORMAT_RFC2822)
    : undefined;
  const dateTo = dateRange?.to
    ? moment.parseZone(dateRange?.to).locale('en').format(DATE_FORMAT_RFC2822)
    : undefined;

  return {
    dateTo,
    dateFrom,
    category: chosenVariant !== VARIANT_DEFAULT ? chosenVariant : undefined,
  };
};
