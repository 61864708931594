import { Registry, withRegistry } from '@bem-react/di';

import { RegistryTokens } from '@features/CardConnectorDouble/enums/RegistryTokens';
import { CardConnectorDesktop } from '@features/CardConnector/CardConnector@desktop';
import {
  CardConnectorDouble,
  cardConnectorDoubleRegistryToken,
} from '@features/CardConnectorDouble/CardConnectorDouble';

const registry = new Registry({ id: cardConnectorDoubleRegistryToken });

registry.set(RegistryTokens.CARD_CONNECTOR, CardConnectorDesktop);

export const CardConnectorDoubleDesktop = withRegistry(registry)(CardConnectorDouble);
