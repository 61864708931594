import { IVipBusinessClubAbout } from '@features/VipInfo/interfaces/IVipBusinessClubAbout';

import { IVipBusinessClubAboutPreview } from '@common/interfaces/pageBlocks/vipBusinessClubAboutPreview';

export const getVipBusinessClubAbout = (response: IVipBusinessClubAboutPreview): IVipBusinessClubAbout => ({
  description: response.description,
  gallery: response.gallery,
  insideImage: response.insideImage,
  sectorCode: response.sectorCode,
  sectorOnStadiumImage: response.sectorOnStadiumImage,
  page: 'lodges',
  isLodge: response.isLodge,
});
