import { DateUtils, Modifiers } from 'react-day-picker';

import { IDateRange } from '@features/EventSelector/components/DateRangePicker/interfaces/dateRange';
import { isDayInRange } from '@features/EventSelector/components/DateRangePicker/utils/isDayInRange';
import { DayModifierClassNames } from '@features/EventSelector/components/DateRangePicker/enums/DayModifierClassNames';

interface IParams {
  range: IDateRange;
  dateFrom: Date;
  hoveredRange: IDateRange;
}

export const getModifiers = ({
  range,
  dateFrom,
  hoveredRange,
}: IParams): Partial<Modifiers> => {
  const { isDayBefore } = DateUtils;

  const idDayDisabled = (day: Date) => isDayBefore(day, dateFrom);

  const isDayInHoveredRange = (day: Date) => (
    !idDayDisabled(day) && (isDayInRange({ day, range }) || isDayInRange({ day, range: hoveredRange }))
  );

  return {
    [DayModifierClassNames.START]: range.from || undefined,
    [DayModifierClassNames.END]: range.to || undefined,
    [DayModifierClassNames.RANGE_HOVERED]: isDayInHoveredRange,
    [DayModifierClassNames.DISABLED]: idDayDisabled,
  };
};
