import React from 'react';
import classNames from 'classnames';

import { AbonementCardHeader } from '@features/AbonementCard/components/AbonementCardHeader';
import { AbonementTooltip } from '@common/components/AbonementTooltip/AbonementTooltip';
import { UiKitColors } from '@common/enums/UiKitColors';
import { MainButton } from '@features/MainButton/MainButton';
import { IAbonementCard } from '@features/AbonementCard/interfaces/IAbonementCard';
import { Period } from '@features/AbonementCard/components/Period';
import { getAvailablePeriods } from '@features/AbonementCard/utils/getAvailablePeriods';
import { EventButtonKinds } from '@common/enums/EventButtonKinds';

export const AbonementCardDesktop: React.FC<IAbonementCard> = ({
  buttons,
  info,
  salePeriods,
  description,
  name,
  markedAdvantages,
  newLabel,
  icon,
  background = '',
  uiKitColor,
}) => (
  <div
    className={classNames('abonement-card', {
      'abonement-card--basket': uiKitColor === UiKitColors.BASKET,
      'abonement-card--general': uiKitColor === UiKitColors.GENERAL,
    })}
    style={{
      backgroundImage: background ? `url("${background}")` : undefined,
    }}
  >
    <div className='abonement-card__wrapper'>
      <div className='abonement-card__main'>
        <div className='abonement-card__header'>
          <AbonementCardHeader
            newLabel={newLabel}
            title={name}
            icon={icon}
            advantages={markedAdvantages}
          />
          {description && (
            <div
              className='abonement-card__header-info'
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
        <div className='abonement-card__description'>
          {!description && getAvailablePeriods(salePeriods).map((period, idx) => (
            <div
              className='abonement-card__description-item'
              key={idx}
            >
              <div className='abonement-card__description-title'>{period.name}</div>
              <div className='abonement-card-sale__info-text'>
                <Period period={period.period}/>
              </div>
              {period?.info && (
                <div className='abonement-card__tooltip'>
                  <div className='abonement-card__tooltip-text'>{period.info.text}</div>
                  <div
                    className='abonement-card__tooltip-icon'
                    {...(period.info.description ? {
                      'data-for': `abonement-card-${name}`,
                      'data-tip': period.info.description,
                    } : {})}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className='abonement-card__buttons'>
        {buttons.map((button, idx) => (
          <MainButton
            key={idx}
            button={button}
            className='abonement-card__button'
            isBigHeight={true}
            lightHover={button.action.active}
            isOutline={button.action.kind === EventButtonKinds.SUBSCRIBE && button.action.active}
            isLink={button.action.kind === EventButtonKinds.SUBSCRIBED}
          />
        ))}
        {info && (
          <div
            className='abonement-card__info'
            dangerouslySetInnerHTML={{ __html: info }}
          />
        )}
      </div>
    </div>
    <AbonementTooltip
      className='abonement-card__tooltip-item'
      id={`abonement-card-${name}`}
    />
  </div>
);
