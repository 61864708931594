import { Registry, withRegistry } from '@bem-react/di';

import { SliderConnector, sliderConnectorRegistryToken } from '@features/SliderConnector/SliderConnector';
import { SliderDesktop } from '@features/Slider/Slider@desktop';
import { RegistryTokens } from '@features/SliderConnector/enums/RegistryTokens';
import { AbonementSlideDesktop } from '@features/AbonementSlide/AbonementSlide@desktop';
import { EventSlideDesktop } from '@features/EventSlide/EventSlide@desktop';

const registry = new Registry({ id: sliderConnectorRegistryToken });

registry.set(RegistryTokens.SLIDER, SliderDesktop);
registry.set(RegistryTokens.ABONEMENT_SLIDE, AbonementSlideDesktop);
registry.set(RegistryTokens.ABONEMENT_SLIDE, AbonementSlideDesktop);
registry.set(RegistryTokens.EVENT_SLIDE, EventSlideDesktop);

export const SliderConnectorDesktop = withRegistry(registry)(SliderConnector);
