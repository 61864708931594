import React from 'react';

import { SvgUse } from '@common/components/SvgUse/SvgUse';
import { EventLarge } from '@features/Event/Event@large';
import { IEventListCard } from '@features/EventListCard/interfaces/IEventListCard';
import { Card } from '@features/Card/Card';

export const EventListCardDesktop: React.FC<IEventListCard> = ({
  backgroundVideo,
  backgroundImage,
  title,
  events,
  button,
}) => (
  <Card
    backgroundImage={backgroundImage}
    backgroundVideo={backgroundVideo}
    isDisablePadding={true}
  >
    <div className='event-list-card'>
      <div className='event-list-card__header'>
        <div className='event-list-card__title'>{title}</div>
        {button && (
          <a
            className='event-list-card__more'
            href={button.action.href}
          >
            {button.text}
            <SvgUse
              className='event-list-card__more-icon'
              use='arrow-drop'
            />
          </a>
        )}
      </div>
      <div className='event-list-card__list'>
        {events.map((event, idx) => (
          <div
            className='event-list-card__list-item'
            key={idx}
          >
            <EventLarge
              key={idx}
              item={event}
            />
          </div>
        ))}
      </div>
    </div>
  </Card>
);
