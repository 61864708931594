export const DATE_FORMAT_RFC2822 = 'ddd, DD MMM YYYY HH:mm:ss ZZ';

export const INPUT_DATE_FORMAT = 'D MMM';

export const YEAR_FORMAT = 'YYYY';

export const MONTH_FORMAT = 'MMMM';

export const MONTH_WITH_YEAR_FORMAT = 'MMMM YYYY';

export const EVENT_SELECTOR_RESULT_JS_SELECTOR = 'js-event-selector-result';
