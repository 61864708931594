import { useEffect, useState } from 'react';

import { IDateRange } from '@features/EventSelector/components/DateRangePicker/interfaces/dateRange';
import { INIT_RANGE } from '@features/EventSelector/components/DateRangePicker/utils/constants';
import { useCurrentDate } from '@features/EventSelector/components/DateRangePicker/hooks/useCurrentDate';
import { useOnDayClick } from '@features/EventSelector/components/DateRangePicker/hooks/useOnDayClick';
import { useOnDayMouseEnter } from '@features/EventSelector/components/DateRangePicker/hooks/useOnDayMouseEnter';

interface IParams {
  onDateRangeChange: (range: IDateRange) => void;
  range: IDateRange;
}

export const useDateRange = ({ onDateRangeChange, range }: IParams) => {
  const [hoveredRange, setHoveredRange] = useState<IDateRange>(INIT_RANGE);

  const {
    currentDate,
    setCurrentDate,
  } = useCurrentDate({ range });

  const onDayClick = useOnDayClick({ range, onDateRangeChange, currentDate, setCurrentDate });

  const onDayMouseEnter = useOnDayMouseEnter({ range, currentDate, setHoveredRange });

  useEffect(() => {
    setHoveredRange(INIT_RANGE);
    onDateRangeChange(range);
  }, [range]);

  const onDayMouseLeave = () => setHoveredRange(INIT_RANGE);

  return {
    range,
    hoveredRange,
    currentDate,
    setCurrentDate,
    onDayClick,
    onDayMouseEnter,
    onDayMouseLeave,
  };
};
