import { useEffect, useState } from 'react';

import { pushAnalytics } from '@common/utils/pushAnalytics';
import { IGoogleAnalyticsEvent } from '@modules/tracker/interfaces/IGoogleAnalyticsEvent';

export const usePushAnalytics = (analytics?: IGoogleAnalyticsEvent[]) => {
  const [isWindowLoaded, setIsWindowLoaded] = useState(false);

  useEffect(() => {
    const onLoad = () => {
      pushAnalytics(analytics);
      setIsWindowLoaded(true);
    };

    window.addEventListener('load', onLoad);

    return () => window.removeEventListener('load', onLoad);
  }, []);

  useEffect(() => {
    if (!isWindowLoaded) {
      return;
    }

    pushAnalytics(analytics);
  }, [analytics]);
};
