import { all, call, getContext, put, takeEvery } from 'redux-saga/effects';
import httpStatusCodes from 'http-status-codes';

import { asyncActions, actions } from '@features/MainButton/duck/slice';
import { FetchStatus } from '@common/enums/FetchStatus';
import { CalendarApiService } from '@common/api/CalendarApiService';
import { ServiceTokens } from '@common/enums/ServiceTokens';
import { ApiResponse } from '@common/api/ApiResponse';
import { IPersonRestriction } from '@common/interfaces/calendarResponse/personRestriction';

export function* checkPersonRestrictions(action: ReturnType<typeof asyncActions.onCheckPersonRestrictions>) {
  try {
    yield put(actions.setFetchStatus({
      fetchStatus: FetchStatus.IN_PROGRESS,
      id: action.payload.calendarBitrixId,
    }));

    const apiService: CalendarApiService = yield getContext(ServiceTokens.CALENDAR_API_SERVICE);

    const response: ApiResponse<IPersonRestriction> = yield call([
      apiService, apiService.checkPersonRestrictions,
    ], action.payload.calendarBitrixId);

    window.history.pushState({ path: window.location.href }, '', window.location.href);

    if (Array.isArray(response.payload)) {
      yield window.location.replace(action.payload.redirectLocation);

      return;
    }

    yield window.location.replace(response.payload.url);
  } catch (e) {
    if (e.response?.code === httpStatusCodes.UNAUTHORIZED) {
      yield put(actions.setFetchStatus({
        fetchStatus: FetchStatus.SUCCESS,
        id: action.payload.calendarBitrixId,
      }));

      window.showAuthPopup();

      return;
    }

    yield put(actions.setFetchStatus({
      fetchStatus: FetchStatus.FAILED,
      id: action.payload.calendarBitrixId,
      error: e?.response.message,
    }));
  }
}

function* onCheckPersonRestrictions() {
  yield takeEvery(asyncActions.onCheckPersonRestrictions.type, checkPersonRestrictions);
}

export function* saga() {
  yield all([
    onCheckPersonRestrictions(),
  ]);
}
