import { IType } from '@common/interfaces/commonResponse/type';
import { IAbonementCard } from '@features/AbonementCard/interfaces/IAbonementCard';

export const getAbonementCardFromResponse = (type: IType): IAbonementCard => ({
  icon: type.icon || '',
  name: type.name,
  advantages: type.advantages,
  markedAdvantages: type.markedAdvantages,
  salePeriods: type.salePeriods,
  newLabel: type.newLabel,
  buttons: type.buttons,
  background: type.background,
  info: type.alertText,
  uiKitColor: type.uiKitColor,
  code: type.code,
  description: type.note,
});
