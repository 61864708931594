import React from 'react';

import { IBackgroundFile } from '@common/interfaces/commonResponse/backgroundFile';
import { Video } from '@common/components/Video/Video';

export interface IProps {
  backgroundImage: IBackgroundFile | null;
  backgroundVideo: IBackgroundFile | null;
}

export const SliderBackground: React.FC<IProps> = ({ backgroundImage, backgroundVideo }) => (
  <div
    className='slider__background'
    style={{
      backgroundImage: backgroundImage ? `url("${backgroundImage.path}")` : undefined,
    }}
  >
    {backgroundVideo && (
      <Video
        src={backgroundVideo.path}
        poster={backgroundImage?.path}
        className='slider__video'
      />
    )}
  </div>
);
