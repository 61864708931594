import React from 'react';
import Transition from 'react-transition-group/Transition';
import classNames from 'classnames';

import { IButton } from '@common/components/Popup/interfaces/button';
import { Button } from '@common/components/Popup/components/Button';
import { SvgUse } from '@common/components/SvgUse/SvgUse';
import { CardNotCloseJsSelectors } from '@features/StadiumMain/enums/cardNotCloseJsSelectors';

interface IProps {
  isShown?: boolean;
  onClose?: () => void;
  title?: string;
  onUnmount?: () => void;
  className?: string;
  buttons?: IButton[];
  contentClassNames?: string;
}

export const Popup: React.FC<IProps> = ({
  isShown,
  onClose,
  title,
  children,
  onUnmount,
  className,
  buttons,
  contentClassNames,
}) => (
  <Transition
    timeout={200}
    in={isShown}
    mountOnEnter={true}
    unmountOnExit={true}
    onExited={onUnmount}
  >
    {state => (
      <div
        className={classNames('rounded-popup', {
          [`rounded-popup--${state}`]: state,
        }, className, CardNotCloseJsSelectors.POPUP)}
      >
        <div className={classNames('rounded-popup__content', {}, contentClassNames)}>
          <div className='rounded-popup__header'>
            {title && (
              <div className='rounded-popup__title'>
                {title}
              </div>
            )}
            {onClose && (
              <div
                className='rounded-popup__close'
                onClick={onClose}
              >
                <SvgUse use='cross'/>
              </div>
            )}
          </div>

          <div className='rounded-popup__children'>
            {children}
          </div>

          {buttons && (
            <div className='rounded-popup__buttons-block'>
              {buttons.map((button, idx) => (
                <div
                  key={idx}
                  className='rounded-popup__buttons-item'
                >
                  <Button button={button}/>
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          className='rounded-popup__background'
          onClick={onClose}
        />
      </div>
    )}
  </Transition>
);
