import { IVipBusinessClubContactInfo } from '@features/VipInfo/interfaces/IVipBusinessClubContactInfo';
import { IVipBusinessClubContactInfoPreview } from '@common/interfaces/pageBlocks/vipBusinessClubContactInfoPreview';
export const getVipBusinessClubContactInfo
    = (response: IVipBusinessClubContactInfoPreview): IVipBusinessClubContactInfo => ({
      phone: response.phone,
      telegramLink: response.telegramLink,
      viberLink: response.viberLink,
      whatsappLink: response.whatsappLink,
      page: 'lodges',
    });
