import { useCallback, useState } from 'react';

export const usePauseSlide = () => {
  const [isPause, setIsPause] = useState(false);

  const onMouseEnter = useCallback(() => {
    setIsPause(true);
  }, [setIsPause]);

  const onMouseLeave = useCallback(() => {
    setIsPause(false);
  }, [setIsPause]);

  return {
    isPause,
    onMouseEnter,
    onMouseLeave,
  };
};
