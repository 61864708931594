import React from 'react';
import DayPicker from 'react-day-picker';
import { DayPickerProps } from 'react-day-picker/types/Props';

export const classNames = {
  container: 'date-picker',
  wrapper: 'date-picker__wrapper',
  interactionDisabled: 'date-picker--interaction--disabled',
  months: 'date-picker__months',
  month: 'date-picker__month',

  navBar: 'date-picker__nav-bar',
  navButtonPrev: 'date-picker__nav-button date-picker__nav-button--prev',
  navButtonNext: 'date-picker__nav-button date-picker__nav-button--next',
  navButtonInteractionDisabled: 'date-picker__nav-button--interaction--disabled',

  caption: 'date-picker__caption',
  weekdays: 'date-picker__weekdays',
  weekdaysRow: 'date-picker__weekdays-row',
  weekday: 'date-picker__weekday',
  body: 'date-picker__body',
  week: 'date-picker__week',
  weekNumber: 'date-picker__week-number',
  day: 'date-picker__day',
  footer: 'date-picker__footer',
  todayButton: 'date-picker__today-button',

  // default modifiers
  today: 'date-picker__day--today',
  selected: 'date-picker__day--selected',
  disabled: 'date-picker__day--disabled',
  outside: 'date-picker__day--outside',
};

export const DayPickerWrapper: React.FC<DayPickerProps> = props => (
  <DayPicker
    {...props}
    classNames={classNames}
  />
);

export default DayPickerWrapper;
