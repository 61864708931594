import { IType } from '@common/interfaces/commonResponse/type';
import { ICardType } from '@features/AbonementTypes/interfaces/IType';
import { CardTypes } from '@features/AbonementTypes/enums/CardTypes';
import { getAbonementCardFromResponse } from '@features/AbonementCard/utils/getAbonementCardFromResponse';

export const getAbonementCardTypeFromResponse = (type: IType): ICardType => ({
  type: CardTypes.CARD,
  shortName: type.shortName || type.name,
  card: getAbonementCardFromResponse(type),
});
