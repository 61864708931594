import React from 'react';
import classNames from 'classnames';

import { SvgUse } from '@common/components/SvgUse/SvgUse';
import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';

export interface IProps {
  eventSize: EventSizeTypes;
  caption: string;
  captionLink?: string;
  isLight?: boolean;
  isWidthFull?: boolean;
}

export const ButtonsCaption: React.FC<IProps> = ({
  eventSize = EventSizeTypes.LARGE,
  caption,
  captionLink,
  isLight,
  isWidthFull,
}) => (
  <div
    className={classNames('buttons-caption', {
      'buttons-caption--light': isLight,
      'buttons-caption--full-width': isWidthFull,
      [`buttons-caption--${eventSize}`]: eventSize,
    })}
  >
    <SvgUse
      use='attention'
      className={classNames('buttons-caption__icon', {
        'buttons-caption__icon--light': isLight,
      })}
    />
    {captionLink && (
      <a href={captionLink}>
        <span dangerouslySetInnerHTML={{ __html: caption }}/>
      </a>
    )}
    {!captionLink && (
      <span dangerouslySetInnerHTML={{ __html: caption }}/>
    )
    }
  </div>
);
