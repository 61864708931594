import React from 'react';

import star from '!!raw-loader!@img/icons/loader-star.svg';

export const Preloader = () => (
  <div
    className='preloader preloader--star'
    dangerouslySetInnerHTML={{ __html: star }}
  />
);
