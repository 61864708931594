import { IEventSelector as IEventSelectorResponse } from '@common/interfaces/pageBlocks/eventSelector';
import { IEventSelector } from '@features/EventSelector/interfaces/eventSelector';

export const getEventSelectorFromResponse = (eventSelector: IEventSelectorResponse, title: string): IEventSelector => ({
  title,
  dateFrom: new Date(eventSelector.dateFrom.value),
  variants: eventSelector.eventCategories.map(category => ({
    value: category.code,
    label: category.name,
  })),
});
