import React, { useState } from 'react';
import classNames from 'classnames';

import { SvgUse } from '@common/components/SvgUse/SvgUse';
import { useSetSizeWithRotate } from '@common/hooks/useSetSizeWithRotate';
import { ItemPosition } from '@features/ScrollBar/enums/ItemPosition';
import { ScrollBarTransition } from '@features/ScrollBar/components/ScrollBarTransition';

export interface IProps {
  onClickNext: () => void;
  onClickPrev: () => void;
  prevItem?: string;
  nextItem?: string;
}

export const ScrollBar: React.FC<IProps> = ({ onClickNext, onClickPrev, prevItem, nextItem }) => {
  const { wrapperRef, elementRef } = useSetSizeWithRotate();

  const [itemPosition, setItemPosition] = useState<ItemPosition | null>(null);

  return (
    <div className='scroll-bar'>
      <div
        className='scroll-bar__wrapper'
        ref={wrapperRef}
      >
        <div
          className='scroll-bar__items'
          ref={elementRef}
        >
          <div
            className={classNames('scroll-bar__item', {
              'scroll-bar__item--disabled': itemPosition === ItemPosition.TOP,
            })}
            onClick={onClickNext}
            onMouseOver={() => setItemPosition(ItemPosition.BOTTOM)}
            onMouseOut={() => setItemPosition(null)}
          >
            <ScrollBarTransition item={nextItem}>
              {nextItem && (
                <div className='scroll-bar__item-wrapper'>
                  <SvgUse
                    use='arrow-long'
                    className='scroll-bar__item-icon'
                  />
                  <div className='scroll-bar__item-text'>
                    {nextItem}
                  </div>
                </div>
              )}
            </ScrollBarTransition>
          </div>
          <div className='scroll-bar__divider'/>
          <div
            className={classNames('scroll-bar__item', {
              'scroll-bar__item--disabled': itemPosition === ItemPosition.BOTTOM,
            })}
            onClick={onClickPrev}
            onMouseOver={() => setItemPosition(ItemPosition.TOP)}
            onMouseOut={() => setItemPosition(null)}
          >
            <ScrollBarTransition item={prevItem}>
              {prevItem && (
                <div className='scroll-bar__item-wrapper'>
                  <div className='scroll-bar__item-text'>
                    {prevItem}
                  </div>
                  <SvgUse
                    use='arrow-long'
                    className='scroll-bar__item-icon'
                  />
                </div>
              )}
            </ScrollBarTransition>
          </div>
        </div>
      </div>
    </div>
  );
};
