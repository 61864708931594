export enum ResponsePageBlocks {
  ABONEMENT_TEXT = 'text',
  ABONEMENT_TYPES = 'abonement_types',
  SLIDER = 'slider',
  INFOGRAPHICS = 'abonement_infographics',
  EVENT_SELECTOR = 'event_finder',
  EVENT_LIST = 'event_list',
  CARD = 'card',
  VIP_COVER = 'vip_cover',
  VIP_SERVICES = 'vip_services',
  VIP_BUSINESS_CLUBS_PREVIEW = 'vip_business_clubs_preview',
  VIP_LODGES_PREVIEW = 'vip_lodges_preview',
  VIP_ABOUT = 'vip_about',
  VIP_CLOSEST_EVENTS = 'vip_closest_events',
  VIP_CONTACT_INFO = 'vip_contact_info',
  VIP_FOOD_MENU = 'vip_food_menu',
  VIP_ORDER_FORM = 'vip_order_form',
  VIP_SERVICE_PACKAGES = 'vip_service_packages',
  VIP_BUSINESS_PROGRAM = 'vip_business_program',
  VIP_OTHER_BC = 'vip_other_business_clubs',
  VIP_ENTERTAINMENT = 'vip_entertainment',
}
