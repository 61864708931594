import React from 'react';

import { IAdvantage } from '@common/interfaces/commonResponse/advantage';
import { Emoji } from '@common/components/Emoji/Emoji';
import { SvgUse } from '@common/components/SvgUse/SvgUse';

export interface IProps {
  advantage: IAdvantage;
  tooltipId: string;
}

export const AbonementCardHeaderIcon: React.FC<IProps> = ({ advantage, tooltipId }) => (
  <div
    className='abonement-card-header__icon'
    data-for='abonement-card-icon'
    {...(advantage.description ? {
      'data-tip': advantage.description,
      'data-for': tooltipId,
    } : {})}
  >
    {advantage.icon ? (
      <Emoji
        size={20}
        icon={advantage.icon}
      />
    ) : (
      <div className='abonement-card-header__icon-default'>
        <SvgUse use='star'/>
      </div>
    )}
  </div>
);
