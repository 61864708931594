import { ApiResponse } from '@common/api/ApiResponse';

export class ApiError<T> extends Error {
  public response?: ApiResponse<T>;

  constructor(response?: ApiResponse<T>, message?: string) {
    super(message);

    this.response = response;
  }
}

export default ApiError;
