import { all, call, getContext, put, takeEvery } from 'redux-saga/effects';

import { ServiceTokens } from '@common/enums/ServiceTokens';
import { IPageResponse } from '@common/interfaces/pageResponse/pageResponse';
import { ApiResponse } from '@common/api/ApiResponse';
import { FetchStatus } from '@common/enums/FetchStatus';
import { actions, asyncActions } from '@features/DynamicPage/components/MainPage/duck/slice';
import { MainPageApiService } from '@common/api/MainPageApiService';

function* initMain() {
  try {
    yield put(actions.setFetchStatus(FetchStatus.IN_PROGRESS));

    const apiService: MainPageApiService = yield getContext(ServiceTokens.MAIN_PAGE_API_SERVICE);

    const response: ApiResponse<IPageResponse> = yield call([apiService, apiService.getMainPageInfo]);

    yield all([
      put(actions.initMain(response.payload)),
      put(actions.setFetchStatus(FetchStatus.SUCCESS)),
    ]);
  } catch (e) {
    yield put(actions.setFetchStatus(FetchStatus.FAILED, e.response?.message));
  }
}

function* onMainInit() {
  yield takeEvery(asyncActions.onInitMainPage.type, initMain);
}

export function* saga() {
  yield all([
    onMainInit(),
  ]);
}
