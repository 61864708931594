import { IVipCover as IVipCoverResponse } from '@common/interfaces/pageBlocks/vipCover';
import { IVipCover } from '@features/VipCover/interfaces/IVipCover';

export const getVipCoverFromResponse = (response: IVipCoverResponse): IVipCover => ({
  title: response.name,
  backgroundImage: response.imagePath,
  backgroundVideo: response.videoPath,
  subTitle: response.shortDescription,
  description: response.description,
  mobileDescription: response.mobileDescription,
  logoPath: response.logoPath,
  phone: response.phone,
  telegramLink: response.telegramLink,
  viberLink: response.viberLink,
  whatsappLink: response.whatsappLink,
  button: response.button,
});
