import { IPageBlockResponse } from '@common/interfaces/pageResponse/pageResponse';
import { ResponsePageBlocks } from '@common/enums/ResponsePageBlocks';
import { getCardFromCardResponse } from '@features/CardConnector/utils/getCardFromCardResponse';
import { getCardFromEventListResponse } from '@features/CardConnector/utils/getCardFromEventListResponse';

export const getNextBlockData = (nextBlock: IPageBlockResponse) => {
  if (!nextBlock?.isHalfWidth) {
    return;
  }

  if (nextBlock.code === ResponsePageBlocks.CARD) {
    return getCardFromCardResponse(nextBlock.state);
  }

  if (nextBlock.code === ResponsePageBlocks.EVENT_LIST && nextBlock.state?.events.length < 2) {
    return getCardFromEventListResponse(nextBlock.state);
  }
};
