import { useEffect, useRef, useState } from 'react';
import { ArrowLeft, ArrowRight, getCode } from 'keyboard-key';

export interface IParams {
  slides: Object[];
  onNext?: () => void;
  onPrev?: () => void;
  timeout?: number;
  isPause?: boolean;
}

const createDefaultTimer = (timeout: number | undefined) => ({
  startTime: Date.now(),
  plannedTime: timeout ?? 0,
});

export const useSlider = ({ slides, onNext, onPrev, timeout, isPause }: IParams) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const timerRef = useRef(createDefaultTimer(timeout));

  const nextSlide = () => {
    onNext && onNext();

    setActiveSlide(slide => slides[slide + 1] ? slide + 1 : 0);
  };

  const prevSlide = () => {
    onPrev && onPrev();

    setActiveSlide(slide => slides[slide - 1] ? slide - 1 : slides.length - 1);
  };

  useEffect(() => {
    if (timeout === undefined) {
      return;
    }

    if (isPause) {
      const lastSlidingTime = Date.now() - timerRef.current.startTime;

      const plannedTime = timerRef.current.plannedTime - lastSlidingTime;

      timerRef.current = {
        ...timerRef.current,
        plannedTime,
      };

      return;
    }

    const id = setTimeout(() => {
      timerRef.current = createDefaultTimer(timeout);
      nextSlide();
    }, timerRef.current.plannedTime);

    timerRef.current = {
      ...timerRef.current,
      startTime: Date.now(),
    };

    return () => clearTimeout(id);
  }, [activeSlide, isPause]);

  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      const code = getCode(e);

      switch (code) {
      case ArrowLeft:
        prevSlide();

        return;
      case ArrowRight:
        nextSlide();

        return;
      }
    };

    document.addEventListener('keyup', onKeyUp);

    return () => document.removeEventListener('keyup', onKeyUp);
  }, []);

  return {
    activeSlide,
    setActiveSlide: (activeSlide: number) => {
      timerRef.current = createDefaultTimer(timeout);
      setActiveSlide(activeSlide);
    },
  };
};
