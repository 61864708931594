import { useEffect, useRef } from 'react';

import { setSizeWithRotate } from '@common/utils/setSizeWithRotate';
import { useWindowResizeEffect } from '@common/hooks/useWindowResizeEffect';

export const useSetSizeWithRotate = <T>(deps: T[] = []) => {
  const wrapperRef = useRef<HTMLDivElement|null>(null);
  const elementRef = useRef<HTMLDivElement|null>(null);

  useEffect(() => {
    setSizeWithRotate(wrapperRef.current, elementRef.current);

    const onOrientationChange = () => {
      setTimeout(() => setSizeWithRotate(wrapperRef.current, elementRef.current), 300);
    };

    document.addEventListener('touchmove', onOrientationChange);

    return () => document.removeEventListener('touchmove', onOrientationChange);
  }, [wrapperRef.current, elementRef.current, ...deps]);

  useWindowResizeEffect(() => {
    setSizeWithRotate(wrapperRef.current, elementRef.current);
  }, [wrapperRef.current, elementRef.current, ...deps], 50);

  return {
    wrapperRef,
    elementRef,
  };
};
