import { IType } from '@common/interfaces/commonResponse/type';
import { IAbonementCardSale } from '@features/AbonementCard/interfaces/IAbonementCard';
import { getEventInfoFromResponse } from '@features/Event/utils/getEventInfoFromResponse';

export const getAbonementSaleCardFromResponse = (type: IType): IAbonementCardSale => ({
  icon: type.icon || '',
  name: type.name,
  info: type.alertText,
  advantages: type.advantages,
  events: type.calendars.map(calendar => ({
    ...getEventInfoFromResponse(calendar),
    buttons: undefined,
  })),
  newLabel: type.newLabel,
  markedAdvantages: type.markedAdvantages,
  salePeriods: type.salePeriods,
  buttons: type.buttons,
  uiKitColor: type.uiKitColor,
  code: type.code,
});
