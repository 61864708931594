import React, { MouseEvent } from 'react';

import { IButton } from '@common/interfaces/commonResponse/button';
import { ButtonSizes } from '@features/MainButton/enum/ButtonSizes';
import { getOnButtonClick } from '@features/MainButton/utils/getOnButtonClick';
import { IWithMainButtonState, withMainButtonState } from '@features/MainButton/hocs/withMainButtonState';
import { ButtonModern } from '@common/components/ButtonModern/ButtonModern';
import { getIsFetching } from '@features/MainButton/utils/getIsFetching';

interface IProps {
  button: IButton;
  onClick?: () => void;
  notBlockLinkOnClick?: () => void;
  calendarId?: number;
  full?: boolean;
  lightHover?: boolean;
  className?: string;
  size?: ButtonSizes;
  isOutline?: boolean;
  isGold?: boolean;
  isLong?: boolean;
  isBigHeight?: boolean;
  isLittleFont?: boolean;
  isLink?: boolean;
}

export const MainButtonComponent: React.FC<IProps & IWithMainButtonState> = ({
  button,
  onClick,
  notBlockLinkOnClick,
  fetchStatus,
  showPopup,
  onCheckPersonRestrictions,
  ...props
}) => {
  const onButtonClick = getOnButtonClick({
    action: button.action,
    onCheckPersonRestrictions,
    showPopup,
  });

  const isFetching = getIsFetching({
    action: button.action,
    fetchStatus,
  });

  const onClickHandler = (event: MouseEvent) => {
    if (onClick || onButtonClick) {
      event.preventDefault();

      onButtonClick && onButtonClick();
      onClick && onClick();
    }

    if (notBlockLinkOnClick) {
      notBlockLinkOnClick();
    }
  };

  return (
    <ButtonModern
      href={button.action.href}
      isPreloading={isFetching}
      isActive={button.action.active}
      onClick={onClickHandler}
      target={button.action.isExternalReference ? '_blank' : '_self'}
      {...props}
    >
      {button.text}
    </ButtonModern>
  );
};

export const MainButton = withMainButtonState(MainButtonComponent);
