import { useState } from 'react';

import { breakpointsChecker } from '@common/utils/breakpointsChecker';
import { useWindowResizeEffect } from '@common/hooks/useWindowResizeEffect';

export const useIsSmallHeight = (isTablet: boolean) => {
  const checker = isTablet ? breakpointsChecker.isSmallHeightTablet : breakpointsChecker.isSmallHeight;

  const [isSmallHeight, setIsSmallHeight] = useState(checker());

  useWindowResizeEffect(() => {
    if (checker()) {
      setIsSmallHeight(true);

      return;
    }

    setIsSmallHeight(false);
  });

  return isSmallHeight;
};
