import { IType } from '@common/interfaces/commonResponse/type';
import { getAbonementSaleCardTypeFromResponse } from '@features/AbonementTypes/utils/getAbonementSaleCardTypeFromResponse';
import { getAbonementCardTypeFromResponse } from '@features/AbonementTypes/utils/getAbonementCardTypeFromResponse';

export const getAbonementTypesFromResponse = (types: IType[]) => types.map(type => {
  if (type.isHatTrick) {
    return getAbonementSaleCardTypeFromResponse(type);
  }

  return getAbonementCardTypeFromResponse(type);
});
