import { IInfographics } from '@common/interfaces/pageBlocks/infographics';
import { IAbonementInfoList } from '@features/AbonementInfo/interfaces/IAbonementInfo';

export const getAbonementInfoListFromResponse = ({ round, season }: IInfographics): IAbonementInfoList => ({
  round: round && round.info ? {
    ...round.info,
    button: round.button,
    types: round.types,
  } : null,
  season: season && season.info ? {
    ...season.info,
    button: season.button,
    types: season.types,
  } : null,
});
