import { Registry, withRegistry } from '@bem-react/di';

import { RegistryTokens } from '@features/CardConnector/enums/RegistryTokens';
import { CardConnector, cardConnectorRegistryToken } from '@features/CardConnector/CardConnector';
import { EventSlideDesktop } from '@features/EventSlide/EventSlide@desktop';

const registry = new Registry({ id: cardConnectorRegistryToken });

registry.set(RegistryTokens.EVENT_SLIDE, EventSlideDesktop);

export const CardConnectorDesktop = withRegistry(registry)(CardConnector);
