import { connect, InferableComponentEnhancer } from 'react-redux';
import { Dispatch } from 'redux';

import { FetchStatus } from '@common/enums/FetchStatus';
import { IRootState } from '@apps/main/initStore';
import { actions } from '@features/DynamicPage/components/MainPage/duck/slice';

const mapStateToProps = (state: IRootState) => ({
  error: state.mainPage.error,
  fetchStatus: state.mainPage.fetchStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetPopup: (error: string | null) => dispatch(actions.setFetchStatus(FetchStatus.FAILED_CONFIRM, error)),
});

export type IStateProps = ReturnType<typeof mapStateToProps>;

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

export type IWithErrorPopupState = IStateProps & IDispatchProps;

export const withErrorPopupState: InferableComponentEnhancer<IWithErrorPopupState> = component => (
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(component)
);
