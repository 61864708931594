import moment from 'moment';

import { DATE_FORMAT_YEAR } from '@features/Event/utils/constants';
import { IEventInfoDate } from '@features/Event/interfaces/eventInfo';

export const getIsEventDateNextYear = (date: IEventInfoDate) => {
  const eventYear = parseInt(moment.parseZone(date.value).format(DATE_FORMAT_YEAR));
  const currentYear = moment().year();

  return eventYear > currentYear;
};
