import { ICard as ICardResponse } from '@common/interfaces/pageBlocks/card';
import { ICard } from '@features/CardConnector/interfaces/ICard';
import { CardTypes } from '@features/CardConnector/enums/CardTypes';

export const getCardFromCardResponse = (response: ICardResponse): ICard => ({
  type: CardTypes.TEXT,
  backgroundImage: response.backgroundImage,
  backgroundVideo: response.backgroundVideo,
  card: {
    button: response.button,
    title: response.title,
  },
});
