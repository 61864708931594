import { withRegistry } from '@bem-react/di';
import { hot } from 'react-hot-loader/root';

import { App, registry } from '@apps/main/app';
import { MainRegistryToken } from '@common/enums/MainRegistryToken';
import { SliderConnectorDesktop } from '@features/SliderConnector/SliderConnector@desktop';
import { MainPageDesktop } from '@features/DynamicPage/components/MainPage/MainPage@desktop';
import { CardConnectorDesktop } from '@features/CardConnector/CardConnector@desktop';
import { CardConnectorDoubleDesktop } from '@features/CardConnectorDouble/CardConnectorDouble@desktop';
import { EventListCardDesktop } from '@features/EventListCard/EventListCard@desktop';
import { EventSelectorConnectorDesktop } from '@features/EventSelectorConnector/EventSelectorConnector@desktop';

registry.set(MainRegistryToken.SLIDER_CONNECTOR, SliderConnectorDesktop);
registry.set(MainRegistryToken.MAIN_PAGE, MainPageDesktop);
registry.set(MainRegistryToken.CARD_CONNECTOR, CardConnectorDesktop);
registry.set(MainRegistryToken.CARD_CONNECTOR_DOUBLE, CardConnectorDoubleDesktop);
registry.set(MainRegistryToken.EVENT_LIST_CARD, EventListCardDesktop);
registry.set(MainRegistryToken.EVENT_SELECTOR_CONNECTOR, EventSelectorConnectorDesktop);

export const AppDesktop = hot(withRegistry(registry)(App));
