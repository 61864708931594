import React from 'react';
import classNames from 'classnames';
import { CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group';

import { useSlider } from '@features/Slider/hooks/useSlider';
import { ISlideBase } from '@features/Slider/interfaces/ISlideBase';
import { useOnButtonHover } from '@common/hooks/useOnButtonHover';
import { SliderBackground } from '@features/Slider/components/SliderBackground';
import { usePauseSlide } from '@features/Slider/hooks/usePauseSlide';

export interface IProps {
  slides: ISlideBase[];
  children: React.ReactNode[];
  timeout?: number;
  isNotBorderedBottom?: boolean;
}

export const SliderDesktop: React.FC<IProps> = ({ slides, children, timeout, isNotBorderedBottom }) => {
  const { isPause, onMouseEnter, onMouseLeave } = usePauseSlide();
  const { activeSlide, setActiveSlide } = useSlider({ slides, timeout, isPause });

  const sliderRef = useOnButtonHover();

  return (
    <div
      className={classNames('slider', {
        'slider--not-bordered-bottom': isNotBorderedBottom,
        'slider--paused': isPause,
      })}
      ref={sliderRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className='slider__background-wrapper'>
        <TransitionGroup mode='out-in'>
          <CSSTransition
            key={activeSlide}
            timeout={600}
            classNames='slider__fade-'
          >
            <SliderBackground
              backgroundImage={slides[activeSlide].backgroundImage}
              backgroundVideo={slides[activeSlide].backgroundVideo}
            />
          </CSSTransition>
        </TransitionGroup>
      </div>
      <div className='slider__wrapper'>
        <SwitchTransition mode='out-in'>
          <CSSTransition
            key={activeSlide}
            timeout={300}
            classNames='slider__switch-'
          >
            <div className='slider__item'>
              {children[activeSlide]}
            </div>
          </CSSTransition>
        </SwitchTransition>
        {slides.length > 1 && (
          <div className='slider__navigation'>
            {slides.map((slide, idx) => (
              <div
                className='slider__navigation-item'
                onClick={() => setActiveSlide(idx)}
                key={idx}
              >
                <div
                  className={classNames('slider__navigation-progress', {
                    '_active': activeSlide === idx,
                  })}
                >
                  {activeSlide === idx && (
                    <div
                      className='slider__navigation-progress-line'
                      style={{ animation: `progress-slider ${timeout}ms linear` }}
                    />
                  )}
                </div>
                <div className='slider__navigation-title'>{slide.navigateTitle}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
