import React from 'react';

export interface IProps {
    description: string;
    image: string;
}

export const BeforeMatchInfoBlock: React.FC<IProps> = ({
  image,
  description,
}) => (
  <div className='before-match-info-block'>
    {image && (
      <img
        className='before-match-info-block__image'
        src={image}
        alt=''
      />
    )}
    {description && (
      <div className='before-match-info-block__description'>
        { description }
      </div>
    )}
  </div>
);
