import { IEventList } from '@common/interfaces/pageBlocks/eventList';
import { ICard } from '@features/CardConnector/interfaces/ICard';
import { CardTypes } from '@features/CardConnector/enums/CardTypes';
import { getEventInfoFromResponse } from '@features/Event/utils/getEventInfoFromResponse';
import { DynamicPageBlocks } from '@features/DynamicPage/enums/DynamicPageBlocks';
import { DynamicPageRoutes } from '@common/enums/DynamicPageRoutes';
import { getEventAnalyticsInfo } from '@features/DynamicPage/utils/getEventAnalyticsInfo';

export const getCardFromEventListResponse = (
  response: IEventList,
  blockType?: DynamicPageBlocks,
  pageRoute?: DynamicPageRoutes,
): ICard => {
  if (response.events.length === 1) {
    const eventAnalyticsInfo = getEventAnalyticsInfo({ pageRoute, event: response.events[0] });
    const event = getEventInfoFromResponse(response.events[0], eventAnalyticsInfo?.product);

    return {
      type: CardTypes.EVENT,
      backgroundImage: response.backgroundImage,
      backgroundVideo: response.backgroundVideo,
      card: {
        event: event,
        title: response.title,
      },
    };
  }

  return {
    type: CardTypes.TEXT,
    backgroundImage: response.backgroundImage,
    backgroundVideo: response.backgroundVideo,
    card: {
      button: response.button,
      title: response.title,
    },
  };
};
