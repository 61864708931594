import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPageResponse } from '@common/interfaces/pageResponse/pageResponse';
import { FetchStatus } from '@common/enums/FetchStatus';

export const asyncActions = {
  onInitMainPage: createAction('dynamicPage/main/onInitMain'),
};

const initialState = window['__PRELOADED_STATE__'] as IPageResponse | null;

interface IState {
  response: IPageResponse;
  fetchStatus: FetchStatus;
  error: string | null;
}

export const { actions, reducer } = createSlice({
  name: 'dynamicPage/main',
  initialState: {
    response: initialState || {
      blocks: [],
      analytics: [],
    },
    fetchStatus: initialState ? FetchStatus.SUCCESS : FetchStatus.NONE,
    error: null,
  } as IState,
  reducers: {
    initMain: (state, action: PayloadAction<IPageResponse>) => {
      state.response = action.payload;
    },
    setFetchStatus: {
      reducer: (state, action: PayloadAction<{ fetchStatus: FetchStatus; error: string | null }>) => {
        state.error = action.payload.error;
        state.fetchStatus = action.payload.fetchStatus;
      },
      prepare: (fetchStatus: FetchStatus, error: string | null = null) => ({
        payload: {
          fetchStatus,
          error,
        },
      }),
    },
  },
});
