import React, { useState } from 'react';
import SelectBase from 'react-select';

import { IVariant } from '@features/EventSelector/interfaces/variant';
import { ArrowInput } from '@features/EventSelector/components/ArrowInput/ArrowInput';
import { VARIANT_DEFAULT } from '@features/EventSelector/components/Select/utils/constants';
import { ArrowSelect } from '@features/EventSelector/components/ArrowInput/ArrowSelect';

export interface IProps {
  chosenVariant?: string;
  onChange: (variant: string) => void;
  variants: IVariant[];
  isLight?: boolean;
  isMobile?: boolean;
}

export const Select: React.FC<IProps> = ({
  chosenVariant,
  onChange,
  variants: variantsDefault,
  isLight,
  isMobile,
}) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const variants: IVariant[] = [
    { value: VARIANT_DEFAULT, label: 'что угодно' },
    ...variantsDefault,
  ];

  if (isMobile) {
    return (
      <ArrowSelect
        variants={variants}
        onChange={onChange}
        value={chosenVariant}
        isLight={isLight}
      />
    );
  }

  return (
    <>
      <ArrowInput
        isSelected={isOpenMenu}
        value={variants.find(variant => variant.value === chosenVariant)?.label}
        onClick={() => setIsOpenMenu(!isOpenMenu)}
        isBig={true}
        onBlur={() => setIsOpenMenu(false)}
        isLight={isLight}
      />

      <SelectBase
        options={variants}
        placeholder={false}
        onChange={value => onChange((value as IVariant).value)}
        menuIsOpen={isOpenMenu}
        onMenuClose={() => setIsOpenMenu(false)}
        styles={{
          container: base => ({
            ...base,
            position: 'absolute',
            width: '100%',
            maxWidth: 250,
          }),
          control: base => ({
            ...base,
            display: 'none',
          }),
          menu: base => ({
            ...base,
            marginTop: 15,
            boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
            borderRadius: 'unset',
            color: '#282828',
            whiteSpace: 'normal',
          }),
        }}
      />
    </>
  );
};
