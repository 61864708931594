import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

export interface IProps {
  className?: string;
  id: string;
  overridePosition?: (position: { left: number; top: number }) => { left: number; top: number };
}

export const AbonementTooltip: React.FC<IProps> = ({ className, id, overridePosition }) => (
  <ReactTooltip
    id={id}
    place='top'
    effect='solid'
    multiline={true}
    globalEventOff='touchstart'
    overridePosition={overridePosition}
    className={classNames('abonement-tooltip', className)}
  />
);
