import { ICalendar } from '@common/interfaces/commonResponse/calendar';
import { getEventInfoFromResponse } from '@features/Event/utils/getEventInfoFromResponse';
import { IEventInfo } from '@features/Event/interfaces/eventInfo';
import { getEventAnalyticsInfo } from '@features/DynamicPage/utils/getEventAnalyticsInfo';
import { DynamicPageRoutes } from '@common/enums/DynamicPageRoutes';

export const getEventsFromResponse = (availableCalendars: ICalendar[] | null): IEventInfo[] | null => (
  availableCalendars?.map(event => {
    const eventAnalyticsInfo = getEventAnalyticsInfo({
      event,
      pageRoute: DynamicPageRoutes.MAIN,
    });

    return getEventInfoFromResponse(event, eventAnalyticsInfo?.product);
  }) || null
);
