import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICalendar } from '@common/interfaces/commonResponse/calendar';
import { IAvailableCalendarsParams } from '@common/interfaces/calendarRequest/availableCalendarsParams';
import { FetchStatus } from '@common/enums/FetchStatus';
import { IDateRange } from '@features/EventSelector/components/DateRangePicker/interfaces/dateRange';

export const asyncActions = {
  onGetAvailableCalendars: createAction<IAvailableCalendarsParams | undefined>('eventSelector/onGetAvailableCalendars'),
};

interface IState {
  availableCalendars: ICalendar[] | null;
  fetchStatus: FetchStatus;
  chosenVariant?: string;
  dateRange: IDateRange;
}

export const { actions, reducer } = createSlice({
  name: 'eventSelector',
  initialState: {
    availableCalendars: null,
    fetchStatus: FetchStatus.NONE,
    dateRange: { from: null, to: null },
  } as IState,
  reducers: {
    setAvailableCalendars: (state, action: PayloadAction<ICalendar[] | null>) => {
      state.availableCalendars = action.payload;
    },
    setFetchStatus: (state, action: PayloadAction<FetchStatus>) => {
      state.fetchStatus = action.payload;
    },
    setChosenVariant: (state, action: PayloadAction<string>) => {
      state.chosenVariant = action.payload;
    },
    setDateRange: (state, action: PayloadAction<IDateRange>) => {
      state.dateRange = action.payload;
    },
  },
});
