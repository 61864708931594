import React from 'react';
import classNames from 'classnames';

import { ITeam } from '@common/interfaces/commonResponse/calendar';
import { Member } from '@features/Event/components/Member';
import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';

export interface IProps {
  teams?: ITeam[];
  eventSize: EventSizeTypes;
  transparent?: boolean;
}

export const TeamsBlock: React.FC<IProps> = ({
  teams,
  eventSize,
  transparent,
}) => (
  <div
    className={classNames('teams-block', {
      [`teams-block--${eventSize}`]: eventSize,
    })}
  >
    {teams?.map((team, index) => (
      <div
        key={team.id}
        className={classNames('teams-block__team', {
          'teams-block__team--left': index + 1 !== teams.length,
          'teams-block__team--right': index + 1 === teams.length,
        })}
      >
        <Member
          logo={team.logo}
          name={team.name}
          isHighlighted={team.isZenit}
          eventSize={eventSize}
          transparent={transparent}
        />
      </div>
    ))}
  </div>
);
