import Axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';

import { ApiResponse, IData } from '@common/api/ApiResponse';
import { createResponseFromAxios } from '@common/api/createResponseFromAxios';

export abstract class ApiServiceBase {
  private readonly axios: AxiosInstance;

  protected constructor() {
    this.axios = Axios.create({
      baseURL: '/',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });

    this.axios.interceptors.request.use(
      config => {
        const tag: HTMLMetaElement | null = document.querySelector('meta[name="sentry-trace"]');

        if (tag === null) {
          return config;
        }

        config.headers['X-Sentry-Trace'] = tag.content;

        return config;
      },
      error => Promise.reject(error)
    );
  }

  /*
  В класс внесены и объедены middleware, так как они необходимы для функицонирования любого API.
  И используются дочерними классами в любом случае
  Из класса убран "then", который производил проверку на instanceof ApiResponse, так как преобразованием данных
  в экземпляр класса ApiResponse занимается createResponseFromAxios, а значит из промиса просто не могут вернуться
  данные другого экземпляра
   */
  private response<T>(promise: AxiosPromise<IData<T>>): Promise<ApiResponse<T>> {
    return createResponseFromAxios<T>(promise);
  }

  protected get<T>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return this.response<T>(this.axios.get(url, config));
  }

  protected post<T, D = {}>(url: string, data?: D, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return this.response<T>(this.axios.post(url, data, config));
  }

  protected delete<T>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return this.response<T>(this.axios.delete(url, config));
  }
}

export default ApiServiceBase;
