import moment from 'moment';

import { Locales } from '@common/enums/Locales';
import { MONTH_FORMAT, MONTH_WITH_YEAR_FORMAT, YEAR_FORMAT } from '@features/EventSelector/utils/constants';

export const getFormattedMonthTitle = (month: Date, locale?: string) => {
  const momentMonth = moment.parseZone(month).locale(locale || Locales.RU);
  const currentYear = moment().year();
  const monthYear = parseInt(momentMonth.format(YEAR_FORMAT));

  return monthYear > currentYear
    ? momentMonth.format(MONTH_WITH_YEAR_FORMAT)
    : momentMonth.format(MONTH_FORMAT);
};
