import { ICalendar } from '@common/interfaces/commonResponse/calendar';
import { IEventInfo } from '@features/Event/interfaces/eventInfo';
import { IGoogleAnalyticsEvent } from '@modules/tracker/interfaces/IGoogleAnalyticsEvent';

export const getEventInfoFromResponse = (event: ICalendar, analyticsInfo?: IGoogleAnalyticsEvent): IEventInfo => ({
  id: event.id,
  bitrixId: event.bitrixId,
  date: event.date ? {
    value: event.date.value,
    time: event.date?.time || undefined,
    needLocalTimeNote: event.date.needLocalTimeNote,
    isDayKnown: event.date.isDayKnown,
  } : undefined,
  buttons: {
    main: event.mainButton,
    additional: event.additionalButton || undefined,
  },
  shortEventDescription: event.shortEventDescription || undefined,
  shortEventDescriptionLink: event.shortEventDescriptionLink || undefined,
  isGuest: event.isGuest,
  teams: event.hostTeam && event.guestTeam ? [
    event.hostTeam,
    event.guestTeam,
  ] : undefined,
  logo: event.tournament.logo || undefined,
  additionalInfo: {
    stadium: event.stadium.name,
    tournament: event.tournament.name,
  },
  name: event.name,
  productNamePlural: event.productType.namePlural,
  isSelected: event.selectedCalendar,
  isAbonementRequired: event.isAbonementRequired,
  reservePriceNote: event.reservePriceNote || '',
  analyticsInfo,
  dateDescription: event.dateDescription,
  isCategoryAMatch: event.isCategoryAMatch || false,
  markAsAdvertisment: event.markAsAdvertisment || false,
  advertismentMarkText: event.advertismentMarkText || '',
  beforeMatchInfoImage: event.beforeMatchInfoImage || '',
  beforeMatchInfoDescription: event.beforeMatchInfoDescription || '',
});
