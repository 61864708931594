import { IVipBusinessClubsPreview } from '@common/interfaces/pageBlocks/vipBussinessClubsPreview';
import { IVipBusinessClubs } from '@features/VipInfo/interfaces/IVipBusinessClubs';

export const getVipBusinessClubsPreview = (response: IVipBusinessClubsPreview): IVipBusinessClubs => ({
  events: response.events,
  items: response.items,
  additionalLink: response.additionalLink,
  googleRecaptchaToken: response.googleRecaptchaToken,
  sportType: response.sportType,
});
