import React from 'react';
import classNames from 'classnames';

import { AdditionalBlock } from '@features/Event/components/AdditionalBlock';
import { MembersBlock } from '@features/Event/components/MembersBlock';
import { DateBlock } from '@features/Event/components/DateBlock';
import { IEventDefaultProps } from '@features/Event/interfaces/eventDefaultProps';
import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';
import { ButtonsBlock } from '@features/Event/components/ButtonsBlock';
import { TextBlock } from '@features/Event/components/TextBlock';
import { BeforeMatchInfoBlock } from '@features/Event/components/BeforeMatchInfoBlock';

export const EventBig: React.FC<IEventDefaultProps> = ({
  item,
  className,
  isLight,
}) => (
  <div
    className={classNames('event-item', {
      'event-item--light': isLight,
    }, `event-item--${EventSizeTypes.BIG}`, className)}
  >
    <div className='event-item__wrapper'>
      <div className='event-item__top'>
        <AdditionalBlock
          stadium={item.additionalInfo.stadium}
          tournament={item.teams && item.additionalInfo.tournament}
          eventSize={EventSizeTypes.BIG}
          isLight={isLight}
        />
      </div>

      <div className='event-item__content'>
        <div className='event-item__left-block'>
          <MembersBlock
            name={item.name}
            logo={item.logo}
            teams={item.teams}
            eventSize={EventSizeTypes.BIG}
          />
          <BeforeMatchInfoBlock
            description={item.beforeMatchInfoDescription}
            image={item.beforeMatchInfoImage}
          />
        </div>

        {item.dateDescription ? (
          <TextBlock
            eventSize={EventSizeTypes.BIG}
            description={item.dateDescription}
          />
        ) : (
          <DateBlock
            date={item.date}
            eventSize={EventSizeTypes.BIG}
            needLocalTimeNote={item.date ? item.date.needLocalTimeNote : false}
          />
        )}

      </div>
    </div>

    {item.buttons && (
      <ButtonsBlock
        buttons={item.buttons}
        calendarId={item.id}
        eventSize={EventSizeTypes.BIG}
        isLight={isLight}
        caption={item.shortEventDescription}
        captionLink={item.shortEventDescriptionLink}
        analytics={item.analyticsInfo}
      />
    )}
  </div>
);
