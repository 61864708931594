import { IGoogleAnalyticsEvent } from '@modules/tracker/interfaces/IGoogleAnalyticsEvent';

export const pushAnalytics = (analytics?: IGoogleAnalyticsEvent[]) => {
  if (!window.zenitTracker || !analytics) {
    return;
  }

  for (const analyticsElement of analytics) {
    window.googleAnalytics && window.googleAnalytics.pushEvent(analyticsElement);
  }
};
