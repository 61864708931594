import { IVipLodges } from '@features/VipInfo/interfaces/IVipLodges';

import { IVipLodgesPreview } from '@common/interfaces/pageBlocks/vipLodgesPreview';

export const getVipLodgesPreview = (response: IVipLodgesPreview): IVipLodges => ({
  items: response.items.map(items => ({
    abonementButton: items.abonementButton,
    advantages: items.advantages,
    events: items.events,
    eventsButton: items.eventsButton,
    gallery: items.gallery,
    id: items.id,
    logo: items.logo,
    priceFrom: items.priceFrom,
    name: items.name,
    sectorCode: items.sectorCode,
    url: items.url,
    type: items.type,
  })),
  googleRecaptchaToken: response.googleRecaptchaToken,
  sportType: response.sportType,
});
