import React, { useMemo } from 'react';
import classNames from 'classnames';

import { FetchStatus } from '@common/enums/FetchStatus';
import { DateRangePicker } from '@features/EventSelector/components/DateRangePicker/DateRangePicker';
import { Select } from '@features/EventSelector/components/Select/Select';
import { IEventSelectorProps } from '@features/EventSelector/interfaces/eventSelectorProps';
import { IWithEventSelectorState, withEventSelectorState } from '@features/EventSelector/hocs/withEventSelectorState';
import { getEventsParams } from '@features/EventSelector/utils/getEventsParams';
import { Header } from '@features/EventSelector/components/Header';
import { Result } from '@features/EventSelector/components/Result';
import { EVENT_SELECTOR_RESULT_JS_SELECTOR } from '@features/EventSelector/utils/constants';

export const EventSelectorComponent: React.FC<IEventSelectorProps & IWithEventSelectorState> = ({
  dateFrom,
  variants,
  title,
  isLight,
  isMobile,
  isTablet,
  onGetAvailableCalendars,
  resetAvailableCalendars,
  fetchStatus,
  events,
  chosenVariant,
  dateRange,
  setChosenVariant,
  setDateRange,
}) => {
  const isEventsFetched = events !== null;
  const isFetching = fetchStatus === FetchStatus.IN_PROGRESS;

  const eventsParams = useMemo(() => getEventsParams({
    dateRange,
    chosenVariant,
  }), [dateRange, chosenVariant]);

  const dateRangeWrapper = useMemo(() => (
    <div className='event-selector__date-picker'>
      <DateRangePicker
        dateFrom={dateFrom}
        dateRange={dateRange}
        isLight={isLight}
        onDateRangeChange={setDateRange}
        isDatePickerUp={isTablet && !isEventsFetched}
        monthCount={isTablet || !isMobile ? 2 : 1}
      />
    </div>
  ), [dateFrom, dateRange, isLight, isLight, isTablet, isMobile]);

  const variantsWrapper = useMemo(() => (
    <div className='event-selector__variants-wrapper'>
      <div className='event-selector__caption'>
        Я хочу сходить на
      </div>

      <div className='event-selector__variants'>
        <Select
          onChange={setChosenVariant}
          variants={variants}
          chosenVariant={chosenVariant}
          isLight={isLight}
          isMobile={isMobile || isTablet}
        />
      </div>
    </div>
  ), [setChosenVariant, variants, chosenVariant, isLight, isMobile]);

  const button = useMemo(() => (
    <div
      className={classNames('event-selector__button', {
        'event-selector__button--disabled': isFetching,
      })}
      onClick={!isFetching ? () => onGetAvailableCalendars(eventsParams) : undefined}
    >
      подобрать
    </div>
  ), [isFetching, onGetAvailableCalendars, eventsParams]);

  return (
    <div
      className={classNames('event-selector', {
        'event-selector--light': isLight,
        'event-selector--compacted': isEventsFetched && isTablet,
        'event-selector--resulted': isEventsFetched,
      })}
    >
      <Header
        title={title}
        eventsCount={events?.length}
        onResetClick={resetAvailableCalendars}
        isEventsFetched={isEventsFetched}
        isMobile={isMobile || isTablet}
      />

      <div className='event-selector__wrapper'>
        {isEventsFetched && isTablet ? (
          <div className='event-selector__content'>
            <div className='event-selector__row'>
              <div className='event-selector__caption'>
                Я буду в Петербурге
              </div>

              {dateRangeWrapper}

            </div>

            <div className='event-selector__row'>
              {variantsWrapper}

              {button}
            </div>
          </div>
        ) : (
          <>
            <div className='event-selector__content'>
              <div className='event-selector__caption'>
                Я буду в Петербурге
              </div>

              {dateRangeWrapper}

              {variantsWrapper}
            </div>

            {button}
          </>
        )}
      </div>

      {events !== null && (
        <Result
          events={events}
          onResetButtonClick={resetAvailableCalendars}
          isMobile={isMobile}
          isTablet={isTablet}
          className={EVENT_SELECTOR_RESULT_JS_SELECTOR}
        />
      )}
    </div>
  );
};

export const EventSelector = withEventSelectorState(EventSelectorComponent);
