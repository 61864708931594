import React, { memo } from 'react';
import classNames from 'classnames';

export interface IProps {
  isActive: boolean;
  isNext: boolean;
  isRevert: boolean;
  isAnimatedIn: boolean;
  isAnimatedOut: boolean;
  onAnimationInStart: () => void;
  onAnimationInEnd: () => void;
  onAnimationOutStart: () => void;
  onAnimationOutEnd: () => void;
  className?: string;
}

export const AnimatedContainer: React.FC<IProps> = memo(({
  isActive,
  isNext,
  isRevert,
  isAnimatedOut,
  isAnimatedIn,
  onAnimationInEnd,
  onAnimationInStart,
  onAnimationOutEnd,
  onAnimationOutStart,
  children,
  className,
}) => (
  <div
    className={classNames(className, {
      '_active': isActive,
      '_next': isNext,
      '_fade-in': isAnimatedIn,
      '_fade-out': isAnimatedOut,
      '_revert': isRevert,
    })}
    onAnimationStart={() => {
      if (isAnimatedIn) {
        onAnimationInStart();

        return;
      }

      if (isAnimatedOut) {
        onAnimationOutStart();
      }
    }}
    onAnimationEnd={() => {
      if (isAnimatedIn) {
        onAnimationInEnd();

        return;
      }

      if (isAnimatedOut) {
        onAnimationOutEnd();
      }
    }}
  >
    {children}
  </div>
));
