import { IType } from '@common/interfaces/commonResponse/type';
import { IAbonementCard } from '@features/AbonementCard/interfaces/IAbonementCard';

export const getAbonementShortCardFromResponse = (type: IType): IAbonementCard => ({
  icon: type.icon || '',
  name: type.name,
  advantages: [],
  markedAdvantages: [],
  salePeriods: type.salePeriods,
  newLabel: {
    ...type.newLabel,
    display: false,
  },
  buttons: type.buttons,
  background: null,
  info: type.alertText,
  uiKitColor: null,
  description: type.note,
});
