import moment from 'moment';

import { Locales } from '@common/enums/Locales';
import { INPUT_DATE_FORMAT } from '@features/EventSelector/utils/constants';

export const getFormattedDay = (day?: Date | null, locale?: string) => {
  if (!day) {
    return '';
  }

  return moment.parseZone(day).locale(locale || Locales.RU).format(INPUT_DATE_FORMAT);
};
