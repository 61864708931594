import React from 'react';
import classNames from 'classnames';

import { ICardBase } from '@features/Card/interfaces/ICardBase';
import { useOnButtonHover } from '@common/hooks/useOnButtonHover';
import { Video } from '@common/components/Video/Video';

export const Card: React.FC<ICardBase> = ({
  children,
  backgroundImage,
  backgroundVideo,
  isShort,
  isRightBorderRadius,
  isDisablePadding,
}) => {
  const ref = useOnButtonHover();

  return (
    <div
      className={classNames('card', {
        'card--short': isShort,
        'card--right-border-radius': isRightBorderRadius,
        'card--padding-0': isDisablePadding,
      })}
      ref={ref}
    >
      <div
        className='card__background'
        style={{ backgroundImage: backgroundImage ? `url("${backgroundImage.path}")` : undefined }}
      >
        {backgroundVideo && (
          <Video
            src={backgroundVideo.path}
            poster={backgroundImage?.path}
            className='card__video'
          />
        )}
      </div>
      <div className='card__content'>
        {children}
      </div>
    </div>
  );
};
