import React, { ComponentType, Suspense } from 'react';

import { Preloader } from '@common/components/Preloader/Preloader';

export const withSuspense = <T extends {}>(Component: ComponentType<T>, preloader?: JSX.Element) => (props: T) => (
  <Suspense
    fallback={preloader || (
      <Preloader/>
    )}
  >
    <Component {...props}/>
  </Suspense>
);
