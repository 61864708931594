import React from 'react';
import { useComponentRegistry } from '@bem-react/di';

import { IComponentRegistry } from '@common/interfaces/abonementComponentRegistry';
import { IAbonementCard } from '@features/AbonementCard/interfaces/IAbonementCard';

export interface IProps {
  card: IAbonementCard;
  title: string;
}

export const abonementSlideRegistryToken = 'abonementSlideRegistryToken';

export const AbonementSlide: React.FC<IProps> = ({
  title,
  card,
}) => {
  const { AbonementCard } = useComponentRegistry<IComponentRegistry>(abonementSlideRegistryToken);

  return (
    <div className='slider-item slider-item--abonement slider-item--height-100'>
      <div className='slider-item__title'>{title}</div>
      <AbonementCard {...card}/>
    </div>
  );
};
