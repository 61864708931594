import { IVipBusinessClubFoodMenuPreview } from '@common/interfaces/pageBlocks/vipBusinessClubFoodMenuPreview';
import { IVipBusinessClubFoodMenu } from '@features/VipInfo/interfaces/IVipBusinessClubFoodMenu';

export const getVipBusinessClubFoodMenu = (response: IVipBusinessClubFoodMenuPreview): IVipBusinessClubFoodMenu => ({
  description: response.description,
  filePath: response.filePath,
  imagePath: response.imagePath,
  isLodge: response.isLodge,
  page: 'lodges',
});
