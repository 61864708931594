import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { Popup } from '@common/components/Popup/Popup';
import {
  IWithMainButtonPopupState,
  withMainButtonPopupState,
} from '@features/MainButton/components/MainButtonPopup/hocs/withMainButtonPopupState';
import { withSuspense } from '@common/hocs/withSuspense';

export const MainButtonPopupComponent: React.FC<IWithMainButtonPopupState> = ({
  popup: {
    text,
    isShown,
    isError,
  },
  resetPopup,
}) => {
  const { t: tt } = useTranslation();

  return (
    <Popup
      title={isError ? tt('error') : undefined}
      isShown={isShown}
      onClose={resetPopup}
    >
      <div>
        {!text && isError
          ? tt('unknown_error')
          : text && parse(text)
        }
      </div>
    </Popup>
  );
};

export const MainButtonPopup = withSuspense(withMainButtonPopupState(MainButtonPopupComponent));
