import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';
import { ButtonSizes } from '@features/MainButton/enum/ButtonSizes';

const buttonsSizes = {
  [EventSizeTypes.LARGE]: ButtonSizes.LARGE,
  [EventSizeTypes.BIG]: ButtonSizes.BIG,
  [EventSizeTypes.MIDDLE]: ButtonSizes.MIDDLE,
  [EventSizeTypes.TINY]: ButtonSizes.TINY,
};

export const getButtonSizeFromEventSize = (eventSize: EventSizeTypes): ButtonSizes => buttonsSizes[eventSize];
