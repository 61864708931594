module.exports = {
    get defaultFontSize() {
        var defaultFontSize = parseInt($('html').css('font-size'));
        if (isNaN(defaultFontSize))
            defaultFontSize = 16;
        return defaultFontSize;
    },
    get mobileSize() {
        return 20 * this.defaultFontSize;
    },
    get tabletSize() {
        return 48*this.defaultFontSize;
    },
    get desktopSmallSize() {
        return 64 * this.defaultFontSize;
    },
    get desktopBigSize() {
        return 75*this.defaultFontSize;
    },
    debounce: function(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    },

    setResizeDebounce: function(func, wait, immediate){
        wait = wait || 250;
        immediate = immediate || false;
        var debouncedFunc = this.debounce(func, wait, immediate);
        window.addEventListener('resize', debouncedFunc);
    },

    /**
     * Возвращяет соответствующий числу вариант склонения слова
     * @param num - число
     * @param words - массив со словами, структура: ['комментарий', 'комментария', 'комментариев']
     * @returns слово
     */
    russianPluralForm: function (num, words) {
        let cases = [2, 0, 1, 1, 1, 2];
        return words[ (num%100>4 && num%100<20)? 2 : cases[(num%10<5)?num%10:5] ];
    },

    /**
     * Находимся ли мы на Eng версии сайта
     *
     * @returns {boolean}
     */
    isEN: function() {
        return BX.message.LANGUAGE_ID == 'en';
    },

    explode: require('locutus/php/strings/explode'),
    implode: require('locutus/php/strings/implode'),
    number_format: require('locutus/php/strings/number_format')
};