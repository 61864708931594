import React from 'react';
import classNames from 'classnames';

import { IButton } from '@common/components/Popup/interfaces/button';
import { ButtonComponentTypes } from '@common/components/Popup/enums/ButtonComponentTypes';
import { ButtonTypes } from '@common/components/Popup/enums/ButtonTypes';

export interface IProps {
  button: IButton;
}

export const Button: React.FC<IProps> = ({
  button,
}) => {
  const buttonClassName = classNames('rounded-popup__button', {
    'rounded-popup__button--filled': button.filledType === ButtonTypes.FILLED || button.filledType === undefined,
    'rounded-popup__button--empty': button.filledType === ButtonTypes.EMPTY,
    'rounded-popup__button--disabled': button.disabled === true,
  });

  switch (button.componentType) {
  case ButtonComponentTypes.BUTTON: {
    return (
      <button
        className={buttonClassName}
        onClick={button.onClick}
        type={button.type ? button.type : undefined}
        disabled={button.disabled}
      >
        {button.children}
      </button>
    );
  }

  case ButtonComponentTypes.LINK: {
    return (
      <a
        href={button.href}
        className={buttonClassName}
        onClick={button.onClick ? e => {
          e.preventDefault();
          button.onClick && button.onClick();
        } : undefined}
      >
        {button.children}
      </a>
    );
  }
  }
};
