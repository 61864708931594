import React, { Dispatch, memo, SetStateAction } from 'react';
import classNames from 'classnames';
import { useComponentRegistry } from '@bem-react/di';
import sortBy from 'lodash/sortBy';

import { IPageBlock } from '@features/DynamicPage/interfaces/IPageBlock';
import { AnimatedContainer } from '@features/DynamicPage/components/MainPage/components/AnimatedContainer';
import { DynamicPageBlocks } from '@features/DynamicPage/enums/DynamicPageBlocks';
import { IState } from '@features/DynamicPage/components/MainPage/hooks/useAnimatePage';
import { IComponentRegistry } from '@common/interfaces/mainComponentRegistry';
import { AppTokens } from '@common/enums/AppTokens';
import { IWithTemplate } from '@common/hocs/withTemplate';
import { DEFAULT_SLIDE_TIME } from '@common/utils/constants';

export interface IProps {
  blocks: IPageBlock[];
  activeItem: number;
  animatedInItem: number|null;
  animatedOutItem: number|null;
  setState: Dispatch<SetStateAction<IState>>;
  isRevert: boolean;
}

export const AnimatedContainerList: React.FC<IProps & IWithTemplate> = memo(({
  blocks,
  activeItem,
  animatedInItem,
  animatedOutItem,
  setState,
  isRevert,
}) => {
  const {
    SliderConnector,
    CardConnector,
    CardConnectorDouble,
    EventListCard,
    EventSelectorConnector,
  } = useComponentRegistry<IComponentRegistry>(AppTokens.MAIN_APP_TOKEN);

  return (
    <>
      {sortBy(blocks, 'sort').map((block, idx) => (
        <AnimatedContainer
          key={idx}
          isActive={idx === activeItem}
          isNext={idx === activeItem + 1}
          isAnimatedIn={idx === animatedInItem}
          isAnimatedOut={idx === animatedOutItem}
          isRevert={isRevert}
          className={classNames('main-page__content-wrapper', {
            'main-page__content-wrapper--slider': block.type === DynamicPageBlocks.SLIDER,
            'main-page__content-wrapper--event-list-card': block.type === DynamicPageBlocks.EVENT_LIST_CARD,
            'main-page__content-wrapper--card': block.type === DynamicPageBlocks.CARD,
            'main-page__content-wrapper--card-double': block.type === DynamicPageBlocks.CARD_DOUBLE,
            'main-page__content-wrapper--event-selector': block.type === DynamicPageBlocks.EVENT_SELECTOR,
            'main-page__content-wrapper--show-top': blocks[idx - 1]?.type !== DynamicPageBlocks.SLIDER,
          })}
          onAnimationInStart={() => setState(state => ({
            ...state,
            animationsStatus: {
              ...state.animationsStatus,
              in: true,
            },
          }))}
          onAnimationOutStart={() => setState(state => ({
            ...state,
            animationsStatus: {
              ...state.animationsStatus,
              out: true,
            },
          }))}
          onAnimationInEnd={() => setState(state => ({
            ...state,
            animationsStatus: {
              ...state.animationsStatus,
              in: false,
            },
            animatedInItem: null,
          }))}
          onAnimationOutEnd={() => setState(state => ({
            ...state,
            animationsStatus: {
              ...state.animationsStatus,
              out: false,
            },
            animatedOutItem: null,
          }))}
        >
          {(() => {
            switch (block.type) {
            case DynamicPageBlocks.SLIDER:
              return (
                <SliderConnector
                  timeout={DEFAULT_SLIDE_TIME}
                  isNotBorderedBottom={true}
                  slides={block.data}
                />
              );
            case DynamicPageBlocks.EVENT_LIST_CARD:
              return (
                <EventListCard {...block.data}/>
              );
            case DynamicPageBlocks.CARD:
              return (
                <CardConnector {...block.data}/>
              );
            case DynamicPageBlocks.CARD_DOUBLE:
              return (
                <CardConnectorDouble cards={block.data}/>
              );
            case DynamicPageBlocks.EVENT_SELECTOR:
              return (
                <EventSelectorConnector {...block.data}/>
              );
            }
          })()}
        </AnimatedContainer>
      ))}
    </>
  );
});
