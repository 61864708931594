import { getVipBusinessClubAbout } from '@features/VipInfo/utils/getVipBusinessClubAbout';

import { ResponsePageBlocks } from '@common/enums/ResponsePageBlocks';
import { IPageResponse } from '@common/interfaces/pageResponse/pageResponse';
import { IPageBlock } from '@features/DynamicPage/interfaces/IPageBlock';
import { getAbonementTextFromResponse } from '@features/AbonementText/utils/getAbonementTextFromResponse';
import { getEventSelectorFromResponse } from '@features/EventSelector/utils/getEventSelectorFromResponse';
import { DynamicPageBlocks } from '@features/DynamicPage/enums/DynamicPageBlocks';
import { IPageBlockSort } from '@features/DynamicPage/interfaces/IPageBlockSort';
import { getAbonementInfoListFromResponse } from '@features/AbonementInfo/utils/getAbonementInfoListFromResponse';
import { getEventListCardFromResponse } from '@features/EventListCard/utils/getEventListCardFromResponse';
import { getCardFromEventListResponse } from '@features/CardConnector/utils/getCardFromEventListResponse';
import { getCardFromCardResponse } from '@features/CardConnector/utils/getCardFromCardResponse';
import { getNextBlockData } from '@features/DynamicPage/utils/getNextBlockData';
import { getVipCoverFromResponse } from '@features/VipCover/utils/getVipCoverFromResponse';
import { getAbonementTypesFromResponse } from '@features/AbonementTypes/utils/getAbonementTypesFromResponse';
import { getSlidesFromResponse } from '@features/SliderConnector/utils/getSlidesFromResponse';
import { getVipBusinessClubsPreview } from '@features/VipInfo/utils/getVipBusinessClubsPreview';
import { getVipLodgesPreview } from '@features/VipInfo/utils/getVipLodgesPreview';
import { getVipBusinessClubClosestEvents } from '@features/VipInfo/utils/getVipBusinessClubClosestEvents';
import { getVipBusinessClubFoodMenu } from '@features/VipInfo/utils/getVipBusinessClubFoodMenu';
import { getVipBusinessClubOrderForm } from '@features/VipInfo/utils/getVipBusinessClubOrderForm';
import { getVipServicePackages } from '@features/VipInfo/utils/getVipServicePackages';
import { getVipBusinessClubContactInfo } from '@features/VipInfo/utils/getVipBusinessClubContactInfo';
import { getVipBusinessProgram } from '@features/VipInfo/utils/getVipBusinessProgram';
import { getVipOtherBC } from '@features/VipInfo/utils/getVipOtherBC';
import { getVipEntertainment } from '@features/VipInfo/utils/getVipEntertainment';

export const getPageBlocksFromResponse = (response: IPageResponse, sort: IPageBlockSort = {}): IPageBlock[] => {
  let isNextSkip = false;

  return response.blocks
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .map((pageBlock, idx): IPageBlock | undefined => {
      if (isNextSkip) {
        isNextSkip = false;

        return undefined;
      }

      const nextBlock = response.blocks[idx + 1];
      const nextBlockData = pageBlock.isHalfWidth && getNextBlockData(nextBlock);

      if (pageBlock.code === ResponsePageBlocks.CARD && pageBlock.state && nextBlockData) {
        isNextSkip = true;

        return {
          type: DynamicPageBlocks.CARD_DOUBLE,
          sort: sort[DynamicPageBlocks.CARD],
          title: `${pageBlock.title} / ${nextBlock.title}`,
          data: [getCardFromCardResponse(pageBlock.state), nextBlockData],
        };
      }

      if (pageBlock.code === ResponsePageBlocks.EVENT_LIST && pageBlock.state && nextBlockData) {
        isNextSkip = true;

        return {
          type: DynamicPageBlocks.CARD_DOUBLE,
          sort: sort[DynamicPageBlocks.CARD_DOUBLE],
          title: `${pageBlock.title} / ${nextBlock.title}`,
          data: [
            getCardFromEventListResponse(pageBlock.state, DynamicPageBlocks.CARD_DOUBLE, response.page),
            nextBlockData,
          ],
        };
      }

      if (pageBlock.code === ResponsePageBlocks.ABONEMENT_TYPES && pageBlock.state?.length) {
        return {
          type: DynamicPageBlocks.ABONEMENT_TYPES,
          sort: sort[DynamicPageBlocks.ABONEMENT_TYPES],
          title: pageBlock.title,
          data: getAbonementTypesFromResponse(pageBlock.state),
        };
      }

      if (pageBlock.code === ResponsePageBlocks.SLIDER && pageBlock.state?.length) {
        return {
          type: DynamicPageBlocks.SLIDER,
          sort: sort[DynamicPageBlocks.SLIDER],
          title: pageBlock.title,
          data: getSlidesFromResponse(pageBlock.state, DynamicPageBlocks.SLIDER, response.page),
        };
      }

      if (pageBlock.code === ResponsePageBlocks.EVENT_SELECTOR && pageBlock.state) {
        return {
          type: DynamicPageBlocks.EVENT_SELECTOR,
          sort: sort[DynamicPageBlocks.EVENT_SELECTOR],
          title: pageBlock.title,
          data: getEventSelectorFromResponse(pageBlock.state, pageBlock.title),
        };
      }

      if (pageBlock.code === ResponsePageBlocks.ABONEMENT_TEXT && pageBlock.state) {
        return {
          type: DynamicPageBlocks.ABONEMENT_TEXT,
          sort: sort[DynamicPageBlocks.ABONEMENT_TEXT],
          title: pageBlock.title,
          data: getAbonementTextFromResponse(pageBlock.state),
        };
      }

      if (pageBlock.code === ResponsePageBlocks.INFOGRAPHICS && pageBlock.state) {
        return {
          type: DynamicPageBlocks.ABONEMENT_INFO,
          sort: sort[DynamicPageBlocks.ABONEMENT_INFO],
          title: pageBlock.title,
          data: getAbonementInfoListFromResponse(pageBlock.state),
        };
      }

      if (pageBlock.code === ResponsePageBlocks.EVENT_LIST && pageBlock.state && pageBlock.state.events.length > 1) {
        return {
          type: DynamicPageBlocks.EVENT_LIST_CARD,
          sort: sort[DynamicPageBlocks.EVENT_LIST_CARD],
          title: pageBlock.title,
          data: getEventListCardFromResponse(pageBlock.state, DynamicPageBlocks.EVENT_LIST_CARD, response.page),
        };
      }

      if (pageBlock.code === ResponsePageBlocks.EVENT_LIST && pageBlock.state) {
        return {
          type: DynamicPageBlocks.CARD,
          sort: sort[DynamicPageBlocks.CARD],
          title: pageBlock.title,
          data: getCardFromEventListResponse(pageBlock.state, DynamicPageBlocks.CARD, response.page),
        };
      }

      if (pageBlock.code === ResponsePageBlocks.CARD && pageBlock.state) {
        return {
          type: DynamicPageBlocks.CARD,
          sort: sort[DynamicPageBlocks.CARD],
          title: pageBlock.title,
          data: getCardFromCardResponse(pageBlock.state),
        };
      }

      if (pageBlock.code === ResponsePageBlocks.VIP_COVER && pageBlock.state) {
        return {
          type: DynamicPageBlocks.VIP_COVER,
          sort: sort[DynamicPageBlocks.VIP_COVER],
          title: pageBlock.title,
          data: getVipCoverFromResponse(pageBlock.state),
        };
      }

      if (pageBlock.code === ResponsePageBlocks.VIP_BUSINESS_CLUBS_PREVIEW && pageBlock.state) {
        return {
          type: DynamicPageBlocks.VIP_BUSINESS_CLUBS,
          sort: sort[DynamicPageBlocks.VIP_BUSINESS_CLUBS],
          title: pageBlock.title,
          data: getVipBusinessClubsPreview(pageBlock.state),
        };
      }
      if (pageBlock.code === ResponsePageBlocks.VIP_LODGES_PREVIEW && pageBlock.state) {
        return {
          type: DynamicPageBlocks.VIP_LODGES,
          sort: sort[DynamicPageBlocks.VIP_LODGES],
          title: pageBlock.title,
          data: getVipLodgesPreview(pageBlock.state),
        };
      }
      if (pageBlock.code === ResponsePageBlocks.VIP_ABOUT && pageBlock.state) {
        return {
          type: DynamicPageBlocks.VIP_ABOUT,
          sort: sort[DynamicPageBlocks.VIP_ABOUT],
          title: pageBlock.title,
          data: getVipBusinessClubAbout(pageBlock.state),
        };
      }
      if (pageBlock.code === ResponsePageBlocks.VIP_CONTACT_INFO && pageBlock.state) {
        return {
          type: DynamicPageBlocks.VIP_CONTACT_INFO,
          sort: sort[DynamicPageBlocks.VIP_CONTACT_INFO],
          title: pageBlock.title,
          data: getVipBusinessClubContactInfo(pageBlock.state),
        };
      }
      if (pageBlock.code === ResponsePageBlocks.VIP_CLOSEST_EVENTS && pageBlock.state) {
        return {
          type: DynamicPageBlocks.VIP_CLOSEST_EVENTS,
          sort: sort[DynamicPageBlocks.VIP_CLOSEST_EVENTS],
          title: pageBlock.title,
          data: getVipBusinessClubClosestEvents(pageBlock.state),
        };
      }
      if (pageBlock.code === ResponsePageBlocks.VIP_BUSINESS_PROGRAM && pageBlock.state) {
        return {
          type: DynamicPageBlocks.VIP_BUSINESS_PROGRAM,
          sort: sort[DynamicPageBlocks.VIP_BUSINESS_PROGRAM],
          title: pageBlock.title,
          data: getVipBusinessProgram(pageBlock.state),
        };
      }
      if (pageBlock.code === ResponsePageBlocks.VIP_OTHER_BC && pageBlock.state) {
        return {
          type: DynamicPageBlocks.VIP_OTHER_BC,
          sort: sort[DynamicPageBlocks.VIP_OTHER_BC],
          title: pageBlock.title,
          data: getVipOtherBC(pageBlock.state),
        };
      }
      if (pageBlock.code === ResponsePageBlocks.VIP_FOOD_MENU && pageBlock.state) {
        return {
          type: DynamicPageBlocks.VIP_FOOD_MENU,
          sort: sort[DynamicPageBlocks.VIP_FOOD_MENU],
          title: pageBlock.title,
          data: getVipBusinessClubFoodMenu(pageBlock.state),
        };
      }
      if (pageBlock.code === ResponsePageBlocks.VIP_ENTERTAINMENT && pageBlock.state) {
        return {
          type: DynamicPageBlocks.VIP_ENTERTAINMENT,
          sort: sort[DynamicPageBlocks.VIP_ENTERTAINMENT],
          title: pageBlock.title,
          data: getVipEntertainment(pageBlock.state),
        };
      }
      if (pageBlock.code === ResponsePageBlocks.VIP_ORDER_FORM && pageBlock.state) {
        return {
          type: DynamicPageBlocks.VIP_ORDER_FORM,
          sort: sort[DynamicPageBlocks.VIP_ORDER_FORM],
          title: pageBlock.title,
          data: getVipBusinessClubOrderForm(pageBlock.state),
        };
      }
      if (pageBlock.code === ResponsePageBlocks.VIP_SERVICE_PACKAGES && pageBlock.state) {
        return {
          type: DynamicPageBlocks.VIP_SERVICE_PACKAGES,
          sort: sort[DynamicPageBlocks.VIP_SERVICE_PACKAGES],
          title: pageBlock.title,
          data: getVipServicePackages(pageBlock.state),
        };
      }
    })
    .filter(item => item) as IPageBlock[];
};
