import React, { useEffect, useRef } from 'react';
import 'objectFitPolyfill';

import { checkIsImageVideoSupport } from '@common/utils/checkIsImageVideoSupport';

export interface IProps {
  src: string;
  className?: string;
  poster?: string;
  type?: string;
}

export const Video: React.FC<IProps> = ({ className, src, type = 'video/mp4', poster }) => {
  const videoRef = useRef<HTMLVideoElement|null>(null);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    objectFitPolyfill(videoRef.current);
  }, [videoRef.current]);

  return (
    <>
      {checkIsImageVideoSupport() ? (
        <img
          src={src}
          className={className}
        />
      ) : (
        <video
          className={className}
          muted={true}
          autoPlay={true}
          loop={true}
          playsInline={true}
          poster={poster}
          ref={videoRef}
        >
          <source
            src={src}
            type={type}
          />
        </video>
      )}
    </>
  );
};
