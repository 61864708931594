import { useState } from 'react';

import { breakpointsChecker } from '@common/utils/breakpointsChecker';
import { useWindowResizeEffect } from '@common/hooks/useWindowResizeEffect';

export const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState(breakpointsChecker.isTablet());

  useWindowResizeEffect(() => {
    if (breakpointsChecker.isTablet()) {
      setIsTablet(true);

      return;
    }

    setIsTablet(false);
  });

  return isTablet;
};
