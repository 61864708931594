import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import { createRootReducer } from '@apps/main/reducers';
import { getLang } from '@common/utils/getLang';
import { ServiceTokens } from '@common/enums/ServiceTokens';
import { MainPageApiService } from '@common/api/MainPageApiService';
import { CalendarApiService } from '@common/api/CalendarApiService';
import { Locales } from '@common/enums/Locales';
import { sagas } from '@apps/main/sagas';

export const history = createBrowserHistory({
  basename: getLang() === Locales.EN ? '/en/' : '/',
});

const sagaMiddleware = createSagaMiddleware({
  context: {
    [ServiceTokens.MAIN_PAGE_API_SERVICE]: new MainPageApiService(getLang()),
    [ServiceTokens.CALENDAR_API_SERVICE]: new CalendarApiService(getLang()),
  },
});

export const store = configureStore({
  reducer: createRootReducer(history),
  middleware: [sagaMiddleware, routerMiddleware(history)],
});

sagaMiddleware.run(sagas);

export type IRootState = ReturnType<typeof store.getState>;
