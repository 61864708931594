export enum EventButtonKinds {
  BUY = 'BUY',
  BUY_FROM = 'BUY_FROM',
  CUSTOM = 'CUSTOM',
  SUBSCRIBE = 'SUBSCRIBE',
  SUBSCRIBED = 'SUBSCRIBED',
  SOLD_OUT = 'SOLD_OUT',
  RESERVE = 'RESERVE',
  ADD_TO_BASKET = 'ADD_TO_BASKET',
  VIP = 'VIP',
  MGN = 'MGN',
}
