export const MAX_NAME_SYMBOLS_COUNT = 22;

export const DATE_FORMAT_WITH_YEAR = 'D MMMM YYYY, dd., HH:mm';

export const DATE_FORMAT_WITH_YEAR_WITHOUT_TIME = 'D MMMM YYYY, dd.';

export const DATE_FORMAT_WITH_YEAR_WITHOUT_DAY = 'MMM YYYY';

export const DATE_FORMAT_WITH_YEAR_LINE_BREAK = 'D MMMM YYYY, dd.[\n]HH:mm';

export const DATE_FORMAT = 'D MMMM, dd., HH:mm';

export const DATE_FORMAT_WITHOUT_TIME = 'D MMMM, dd.';

export const DATE_FORMAT_LINE_BREAK = 'D MMMM, dd.[\n]HH:mm';

export const DATE_FORMAT_YEAR = 'YYYY';

export const DATE_GROUPED = 'D MMMM YYYY';

export const LEGACY_ADD_TO_BASKET_CLASS = 'js-add-to-basket';
