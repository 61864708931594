import React from 'react';
import classNames from 'classnames';

import { SvgUse } from '@common/components/SvgUse/SvgUse';

export interface IProps {
  onClick?: () => void;
  value?: string;
  className?: string;
  onBlur?: () => void;
  isSelected?: boolean;
  isBig?: boolean;
  isLight?: boolean;
}

export const ArrowInput: React.FC<IProps> = ({
  onClick,
  value,
  className,
  onBlur,
  isSelected,
  isBig,
  isLight,
}) => (
  <div
    className={classNames('event-selector__arrow-input-wrapper', {
      'event-selector__arrow-input-wrapper--big': isBig,
    })}
  >
    <input
      type='text'
      value={value}
      className={classNames('event-selector__arrow-input', {
        'event-selector__arrow-input--selected': isSelected,
        'event-selector__arrow-input--light': isLight,
      }, className)}
      onClick={onClick}
      readOnly={true}
      onBlur={onBlur}
    />
    <SvgUse
      use='little-arrow'
      className={classNames('event-selector__arrow-input-icon', {
        'event-selector__arrow-input-icon--selected': isSelected,
        'event-selector__arrow-input-icon--light': isLight,
      })}
    />
  </div>
);
