import React, { useMemo } from 'react';
import { NavbarElementProps } from 'react-day-picker/types/Props';

import { SvgUse } from '@common/components/SvgUse/SvgUse';
import ButtonBase from '@common/components/ButtonBase/ButtonBase.jsx';

export const NavBar: React.FC<Partial<NavbarElementProps>> = ({
  classNames,
  className,
  showPreviousButton,
  showNextButton,
  labels,
  onNextClick,
  onPreviousClick,
}) => {
  const previousClassName = useMemo(() => {
    if (!classNames) {
      return;
    }

    return showPreviousButton
      ? classNames.navButtonPrev
      : `${classNames.navButtonPrev} ${classNames.navButtonInteractionDisabled}`;
  }, [classNames]);

  const nextClassName = useMemo(() => {
    if (!classNames) {
      return;
    }

    return showNextButton
      ? classNames.navButtonNext
      : `${classNames.navButtonNext} ${classNames.navButtonInteractionDisabled}`;
  }, [classNames]);

  return (
    <div className={className || classNames?.navBar}>
      <ButtonBase
        aria-label={labels?.previousMonth}
        className={previousClassName}
        onClick={showPreviousButton && onPreviousClick ? () => onPreviousClick() : undefined}
      >
        <SvgUse use='arrow-drop'/>
      </ButtonBase>

      <ButtonBase
        aria-label={labels?.nextMonth}
        className={nextClassName}
        onClick={showNextButton && onNextClick ? () => onNextClick() : undefined}
      >
        <SvgUse use='arrow-drop'/>
      </ButtonBase>
    </div>
  );
};
