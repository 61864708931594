import { connectRouter, RouterState } from 'connected-react-router';
import { Reducer } from 'redux';
import { History } from 'history';

import { reducer as mainPage } from '@features/DynamicPage/components/MainPage/duck/slice';
import { reducer as eventSelector } from '@features/EventSelector/duck/slice';
import { reducer as mainButton } from '@features/MainButton/duck/slice';

export const createRootReducer = (history: History) => ({
  mainPage,
  eventSelector,
  mainButton,
  router: connectRouter(history) as Reducer<RouterState>,
});
