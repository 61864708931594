import { DynamicPageRoutes } from '@common/enums/DynamicPageRoutes';
import { ICalendar } from '@common/interfaces/commonResponse/calendar';
import { IEventAnalyticsInfo } from '@common/interfaces/commonResponse/eventAnalyticsInfo';

interface IParams {
  event: ICalendar;
  pageRoute?: DynamicPageRoutes;
}

export const getEventAnalyticsInfo = ({
  event,
  pageRoute,
}: IParams): IEventAnalyticsInfo | undefined => {
  if (pageRoute !== DynamicPageRoutes.MAIN) {
    return;
  }

  return {
    product: event.analyticsProduct,
  };
};
