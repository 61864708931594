import React from 'react';
import classNames from 'classnames';

import { AdditionalBlock } from '@features/Event/components/AdditionalBlock';
import { MembersBlock } from '@features/Event/components/MembersBlock';
import { DateBlock } from '@features/Event/components/DateBlock';
import { IEventDefaultProps } from '@features/Event/interfaces/eventDefaultProps';
import { ButtonsBlock } from '@features/Event/components/ButtonsBlock';
import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';
import { EventCardSizeTypes } from '@features/Event/enum/EventCardSizeTypes';

export const EventCardBig: React.FC<IEventDefaultProps> = ({
  item,
  className,
  isLight,
}) => (
  <div
    className={classNames('event-card', {
      'event-card--light': isLight,
    }, `event-card--${EventCardSizeTypes.BIG}`, className)}
  >
    <div className='event-card__wrapper'>
      {item.date && (
        <div className='event-card__top'>
          <DateBlock
            date={item.date}
            eventSize={EventSizeTypes.TINY}
            needLocalTimeNote={item.date ? item.date.needLocalTimeNote : false}
          />
        </div>
      )}

      <div className='event-card__content'>
        <MembersBlock
          name={item.name}
          logo={item.logo}
          teams={item.teams}
          eventSize={EventSizeTypes.LARGE}
        />

        <AdditionalBlock
          stadium={item.additionalInfo.stadium}
          tournament={item.teams && item.additionalInfo.tournament}
          eventSize={EventSizeTypes.LARGE}
          isLight={isLight}
        />
      </div>
    </div>

    {item.buttons && (
      <ButtonsBlock
        buttons={item.buttons}
        calendarId={item.id}
        eventSize={EventSizeTypes.BIG}
        withoutOffsetTop={!item.date}
        isLight={isLight}
        caption={item.shortEventDescription}
        captionLink={item.shortEventDescriptionLink}
        analytics={item.analyticsInfo}
      />
    )}
  </div>
);
