import React from 'react';
import classNames from 'classnames';

import { ITeam } from '@common/interfaces/commonResponse/calendar';
import { TeamsBlock } from '@features/Event/components/TeamsBlock';
import { Member } from '@features/Event/components/Member';
import { MemberName } from '@features/Event/components/MemberName';
import { MAX_NAME_SYMBOLS_COUNT } from '@features/Event/utils/constants';
import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';

export interface IProps {
  name: string;
  logo?: string;
  teams?: ITeam[];
  eventSize: EventSizeTypes;
}

export const MembersBlock: React.FC<IProps> = ({
  teams,
  logo,
  name,
  eventSize,
}) => (
  <>
    {(teams || logo) && (
      <div
        className={classNames('members-block', {
          'members-block--mr-big': !teams,
          [`members-block--${eventSize}`]: eventSize,
        })}
      >
        {teams ? (
          <TeamsBlock
            teams={teams}
            eventSize={eventSize}
          />
        ) : (
          logo && (
            <Member
              logo={logo}
              name={name}
              eventSize={eventSize}
            />
          )
        )}
      </div>
    )}

    <div
      className={classNames('name-block', {
        'name-block--width-big': !teams,
        [`name-block--${eventSize}`]: eventSize,
      })}
    >
      {teams ? (
        <>
          <MemberName
            name={teams[0].name}
            isLittle={teams[0].name ? teams[0].name.length > MAX_NAME_SYMBOLS_COUNT : false}
            isTeam={true}
            eventSize={eventSize}
          />
          <MemberName
            name={teams[1].name}
            isLittle={teams[1].name ? teams[1].name.length > MAX_NAME_SYMBOLS_COUNT : false}
            isTeam={true}
            eventSize={eventSize}
          />
        </>
      ) : (
        <MemberName
          name={name}
          eventSize={eventSize}
        />
      )}
    </div>
  </>
);
