import { useCallback } from 'react';
import { DateUtils, DayModifiers } from 'react-day-picker';

import { isDayInRange } from '@features/EventSelector/components/DateRangePicker/utils/isDayInRange';
import { DayModifierClassNames } from '@features/EventSelector/components/DateRangePicker/enums/DayModifierClassNames';
import { INIT_RANGE } from '@features/EventSelector/components/DateRangePicker/utils/constants';
import { isDateRangeFulfilled } from '@features/EventSelector/components/DateRangePicker/typeGuards/isDateRangeFulfilled';
import { IDateRange } from '@features/EventSelector/components/DateRangePicker/interfaces/dateRange';
import { DateTypes } from '@features/EventSelector/enum/DateTypes';

interface IParams {
  range: IDateRange;
  setHoveredRange: (range: IDateRange) => void;
  currentDate: DateTypes | null;
}

export const useOnDayMouseEnter = ({
  range,
  setHoveredRange,
  currentDate,
}: IParams) => {
  const isCurrentDateTo = currentDate === DateTypes.TO;
  const isCurrentDateFrom = currentDate === DateTypes.FROM;
  const { isDayBefore, isDayAfter } = DateUtils;

  return useCallback((day: Date, modifiers: DayModifiers) => {
    if (isDayInRange({ day, range }) || modifiers[DayModifierClassNames.DISABLED]) {
      setHoveredRange(INIT_RANGE);

      return;
    }

    if (isCurrentDateFrom && isDateRangeFulfilled(range) && isDayBefore(day, range.from)) {
      setHoveredRange({
        from: day,
        to: range.from,
      });

      return;
    }

    if (isCurrentDateTo && isDateRangeFulfilled(range) && isDayAfter(day, range.to)) {
      setHoveredRange({
        from: range.to,
        to: day,
      });

      return;
    }

    if (!range.to && range.from && isDayAfter(day, range.from)) {
      setHoveredRange({
        from: range.from,
        to: day,
      });

      return;
    }

    if (!range.from && range.to && isDayBefore(day, range.to)) {
      setHoveredRange({
        from: day,
        to: range.to,
      });

      return;
    }

    setHoveredRange({ from: day, to: day });
  }, [range, currentDate]);
};
