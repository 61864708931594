import React, { ComponentType, PropsWithChildren } from 'react';
import getDisplayName from 'recompose/getDisplayName';

import { Template } from '@common/enums/Template';

export interface IWithTemplate {
  template: Template;
}

export const withTemplate = (template: Template) => <T extends {}>(Component: ComponentType<T>) => {
  const WithTemplate = (props: PropsWithChildren<Omit<T, keyof IWithTemplate>>) => (
    <Component
      {...props as T}
      template={template}
    />
  );

  WithTemplate.displayName =`WithTemplate(${getDisplayName(Component)})`;

  return WithTemplate;
};

export default withTemplate;
