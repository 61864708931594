import React from 'react';
import { useComponentRegistry } from '@bem-react/di';

import { ICard } from '@features/CardConnector/interfaces/ICard';
import { Card } from '@features/Card/Card';
import { CardTypes } from '@features/CardConnector/enums/CardTypes';
import { TextSlide } from '@features/TextSlide/TextSlide';
import { IComponentRegistry } from '@features/CardConnector/interfaces/IComponentRegistry';

export const cardConnectorRegistryToken = 'cardConnectorRegistryToken';

export const CardConnector: React.FC<ICard> = props => {
  const { EventSlide } = useComponentRegistry<IComponentRegistry>(cardConnectorRegistryToken);

  return (
    <Card {...props}>
      {(() => {
        switch (props.type) {
        case CardTypes.TEXT:
          return (
            <TextSlide
              isFullHeight={props.isShort}
              {...props.card}
            />
          );
        case CardTypes.EVENT:
          return (
            <EventSlide
              isShort={props.isShort}
              {...props.card}
            />
          );
        }
      })()}
    </Card>
  );
};
