import { ISlide as ISlideResponse } from '@common/interfaces/pageBlocks/slide';
import { ISlide } from '@features/SliderConnector/interfaces/ISlide';
import { getSlideFromResponse } from '@features/SliderConnector/utils/getSlideFromResponse';
import { DynamicPageBlocks } from '@features/DynamicPage/enums/DynamicPageBlocks';
import { DynamicPageRoutes } from '@common/enums/DynamicPageRoutes';

export const getSlidesFromResponse = (
  slides: ISlideResponse[],
  blockType?: DynamicPageBlocks,
  pageRoute?: DynamicPageRoutes,
): ISlide[] => slides.map(slide => getSlideFromResponse(slide, blockType, pageRoute));
