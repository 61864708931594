import React from 'react';
import classNames from 'classnames';

import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';

export interface IProps {
  name?: string;
  isLittle?: boolean;
  isTeam?: boolean;
  eventSize: EventSizeTypes;
}

export const MemberName: React.FC<IProps> = ({
  name,
  isLittle,
  isTeam,
  eventSize,
}) => (
  <div
    className={classNames('member-name', {
      'member-name--little': isLittle,
      'member-name--team': isTeam,
      [`member-name--${eventSize}`]: eventSize,
    })}
  >
    {name}
  </div>
);
