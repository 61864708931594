import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { IDatePeriod } from '@common/interfaces/commonResponse/calendar';
import { withSuspense } from '@common/hocs/withSuspense';

export interface IProps {
  period: IDatePeriod;
}

export const PeriodComponent: React.FC<IProps> = ({ period }) => {
  const { t } = useTranslation();

  return (
    <>
      {period.start && `${t('period_from', { from: moment.parseZone(period.start.value).format('DD MMM YYYY') })} `}
      {period.end && t('period_to', { to: moment.parseZone(period.end.value).format('DD MMM YYYY') })}
    </>
  );
};

export const Period = withSuspense(PeriodComponent);
