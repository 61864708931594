export const findSvgSizeFromViewBox = (viewBox?: string) => {
  if (!viewBox) {
    return null;
  }

  const [,, width, height] = viewBox.split(' ');

  return {
    width,
    height,
  };
};
